import axios from 'axios';

export const getSearchPlaceService = async (payload: any) => {

  /*return await axios.get(
    `tenant/${payload.tenantId
    }/placeRoom?filter%5Bname%5D=${payload.text}`,
  );*/
  return await axios.get(
    `tenant/${payload.tenantId
    }/placeRoom`,
  );
};

export const getAllPlaceService = async () => {
  const tenantData: any = localStorage.getItem('tenantId');
  return await axios.get(`tenant/${JSON.parse(tenantData).id}/place`);
};
