import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getLocalStorageItem, getTitleFromPath } from '../../Common/utils';
import { generateDynamicManifest } from '../../Common/dynamicManifest';

import Footer from './Footer';
import Header from './Header';

import PWAPrompt from 'react-ios-pwa-prompt'

export const LayoutWithFooter = () => {
  const currentPath = useLocation().pathname;
  const title =
    getTitleFromPath(currentPath) || getLocalStorageItem('tenantId')?.name;
  
  //to-do ... fix this so favicon is dynamic for all tenants
    //const favicon = title === "The Waterfront Beach Resort" ? "hubwh" : "laquintaresort";
    let favicon;

    switch (title) {
        case "The Waterfront Beach Resort":
            favicon = "hubwh";
            break;
        case "Arizona Biltmore":
            favicon = "azb";
            break;
        case "La Quinta Resort & Club":
            favicon = "laquintaresort";
            break;
        case "OptionN":
            favicon = "iconN";
            break;
        default:
            favicon = "xfavicon"; // Default favicon if title doesn't match any case
    }
    const decodedTitle = decodeURIComponent(title);
    const versionNumber = Math.floor(Math.random() * 1000000);
    const manifestData = generateDynamicManifest(title, favicon);
    console.log('Manifest: ',manifestData);
    

  return (
    <>
      <Helmet>
        <title>{decodedTitle}</title>
        <link
          rel="icon"
          type="image/x-icon" 
          href={`/${favicon}.ico?v=${versionNumber}`}
          sizes='32x32' />
        <link
          rel="icon"
          type="image/svg+xml" 
          href={`/${favicon}.xml?v=${versionNumber}`} />
        <link
          rel="apple-touch-icon"
          href={`/${favicon}-apple-touch-icon.png?v=${versionNumber}`} />
        <link 
          rel="manifest" 
          href={`data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(manifestData))}`} />
      </Helmet>

      <div id="appContainer">
        <Header />
        <Outlet />
        <Footer />
        <div className="app-alert">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnFocusLoss={false}
            // pauseOnHover
          />
        </div>
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={3}
          copyClosePrompt="Cancel"
          copyTitle={`Install ${title}`}
          copyBody="Add this app to your home screen for easy access and a better experience."
        />
      </div>
    </>
  );
};
