import { all } from 'redux-saga/effects';

import promotionSaga from './promotionSaga';
import onlineOrderSaga from './onlineOrderSaga';
import searchPlaceSaga from './searchPlaceSaga';
import sitemapBySlugSaga from './sitemapBySlugSaga';
import sitemapSaga from './sitemapSaga';
import tenantSaga from './tenantSaga';
import tenantProfileSaga from './tenantProfileSaga';
import getMenuSaga from './menuSaga';
import getEventSaga from './eventSaga';
import subTenantSaga from './subTenantSaga';


export default function* rootSaga() {
  yield all([
    promotionSaga(),
    onlineOrderSaga(),
    searchPlaceSaga(),
    sitemapBySlugSaga(),
    sitemapSaga(),
    tenantSaga(),
    tenantProfileSaga(),
    getMenuSaga(),
    getEventSaga(),
    subTenantSaga()
  ]);
}
