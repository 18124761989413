export const generateDynamicManifest = (tenant, shortName) => {
    // Customize manifest based on provided names
    const manifest = {
        short_name: tenant,
        name: `${tenant} - Enhance Your Hotel Experience`,
        icons: [
            {
            src: `${shortName}.ico`,
            sizes: '32x32',
            type: 'image/x-icon'
            },
            {
            src: `${shortName}-192.png`,
            type: 'image/png',
            sizes: '192x192'
            },
            {
            src: `${shortName}-512.png`,
            type: 'image/png',
            sizes: '512x512'
            }
        ],
        start_url: '.',
        display: 'standalone',
        theme_color: '#000000',
        background_color: '#000000'
    };

    return manifest;
};