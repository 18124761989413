import { call, put, takeEvery } from 'redux-saga/effects';

import { getOnlineOrderDataApi } from '../../Api';
import { ONLINE_ORDER_DATA } from '../actionType';

export function* getOnlineOrderData(action: any): any {
    try {
        const onlineOrderData: any = yield call(() => getOnlineOrderDataApi(action.payload));
        yield put({ type: ONLINE_ORDER_DATA.SUCCESS, payload: onlineOrderData });
    } catch (e: any) {
        yield put({ type: ONLINE_ORDER_DATA.FAIL, error: e });
    }
}

export default function* watchOnlineOrderData() {
    yield takeEvery(ONLINE_ORDER_DATA.LOAD, getOnlineOrderData);
}