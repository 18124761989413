import { call, put, takeEvery } from 'redux-saga/effects';
import { getEventApi, getSubTenantEventApi } from '../../Api/eventApi';
import { EVENT_DATA } from '../actionType/eventType';

export function* getEventSaga(action) {
  try {
    const eventData = yield call(() => getEventApi(action.payload));
    yield put({ type: EVENT_DATA.SUCCESS, payload: eventData });
  } catch (e) {
    yield put({ type: EVENT_DATA.FAIL, error: e });
  }
}
export function* getSubTenantEventSaga(action) {
  try {
    const eventData = yield call(() => getSubTenantEventApi(action.payload));
    yield put({ type: EVENT_DATA.SUCCESS_SUBTENANT, payload: eventData });
  } catch (e) {
    yield put({ type: EVENT_DATA.FAIL_SUBTENANT, error: e });
  }
}

export default function* watchEventSaga() {
  yield takeEvery(EVENT_DATA.LOAD, getEventSaga);
  yield takeEvery(EVENT_DATA.LOAD_SUBTENANT, getSubTenantEventSaga);
}
