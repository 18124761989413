import { getSitemapBySlugService } from "../Service";

export const getSitemapBySlugDataApi = async (slug: string) => {
    try {
        let tenantId:any = localStorage.getItem('tenantId');
        const response = await getSitemapBySlugService(JSON.parse(tenantId).id, slug);
        const data = response.data;
        return data
    } catch (error) {
        throw Error
    }
}