import { PROMOTION_DATA } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    promotionData: [],
    promotionDataSubTenant: []

}

const promotionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PROMOTION_DATA.LOAD:
            return {
                ...state,
                loading: true
            };
        case PROMOTION_DATA.SUCCESS:
            return {
                ...state,
                loading: false,
                promotionData: action.payload,
                error: ''
            };
        case PROMOTION_DATA.FAIL:
            return {
                ...state,
                loading: false,
                promotionData: [],
                error: action.error
            }

        case PROMOTION_DATA.LOAD_SUBTENANT:
            return {
                ...state,
                loading: true
            };
        case PROMOTION_DATA.SUCCESS_SUBTENANT:
            return {
                ...state,
                loading: false,
                promotionDataSubTenant: action.payload,
                error: ''
            };
        case PROMOTION_DATA.FAIL_SUBTENANT:
            return {
                ...state,
                loading: false,
                promotionDataSubTenant: [],
                error: action.error
            }



        default:
            return state;
    }
}

export default promotionReducer;
