import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { memo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CONSTS } from '../../Common/CONSTS';
import SimpleImage from '../../Component/SimpleImage';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // component
    mainContainer: {
      padding: '0px 20px',
      marginBottom: 20,
    },

    title: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      textTransform: 'uppercase',
      marginBottom: 20,

      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(2.5vw, 24px)',
        lineHeight: 'min(2.5vw, 24px)',
        paddingTop: 'min(2.5vw, 10px)',
      },
    },

    cards: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      columnGap: 20,
      rowGap: 12,

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
    },

    onlineCard: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: 10,
      backgroundColor: theme.palette.secondary.main,
    },

    cardActionArea: {
      display: 'flex',
      justifyContent: 'flex-start',
      color: '#fff',
    },

    cardImgSkeleton: {
      paddingTop: 80,
    },

    cardContent: {
      alignSelf: 'flex-end',
      padding: 0,
      paddingLeft: 14,
      paddingBottom: 8,
    },
    cardHeading: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      color: theme.palette.getContrastText(theme.palette.secondary.main),

      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(2vw, 16px)',
        lineHeight: 'min(2vw, 16px)',
      },
    },
    cardSubHeading: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '1.25px',
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(1.8vw, 16px)',
        lineHeight: 'min(1.8vw, 16px)',
        paddingTop: 4,
      },
    },
    line: {
      width: '100%',
      height: 1,
      backgroundColor: '#000',
      marginLeft: 20,
      marginBottom: 20,
    },
  }),
);

const OnlineOrder = memo(() => {
  const classes = useStyles();
  const onlineOrderData = useSelector(
    (state: RootStateOrAny) => state.onlineOrderData,
  );

  return (
    onlineOrderData.onlineOrderData?.count > 0 && (
      <motion.div
        initial={{ opacity: 0, x: '-40%' }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: { delay: 0.2, duration: 0.5, ease: 'easeInOut' },
        }}
        viewport={{ once: true }}
      >
        <div className={classes.mainContainer}>
          <Typography variant="h6" className={classes.title}>
            Online Ordering
          </Typography>
          <Box className={classes.cards}>
            {onlineOrderData.onlineOrderData.rows.map(
              ({ name, subhead, url, image }, index) => (
                <Card
                  component={motion.div}
                  whileHover={{ scale: 1.05 }}
                  transition={CONSTS.TRANSITIONS.TEST}
                  key={index}
                  className={classes.onlineCard}
                >
                  <CardActionArea
                    className={classes.cardActionArea}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <SimpleImage
                        src={image[0]?.downloadUrl}
                        alt={name}
                        width={112}
                        height={80}
                        skeletonProps={{
                          className: classes.cardImgSkeleton,
                        }}
                      />
                    </div>

                    <CardContent className={classes.cardContent}>
                      <Typography className={classes.cardHeading} variant="h6">
                        {name}
                      </Typography>
                      <Typography
                        className={classes.cardSubHeading}
                        variant="body2"
                      >
                        {subhead}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ),
            )}
          </Box>
        </div>

        <div className={classes.line} />
      </motion.div>
    )
  );
});

export default OnlineOrder;
