import { EVENT_DATA } from '../actionType/eventType';

const initialState = {
  loading: false,
  error: '',
  eventData: [],
  eventDataSubTenant:[]
};

const eventDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_DATA.LOAD:
      return {
        ...state,
        loading: true,
      };
    case EVENT_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        eventData: action.payload,
        error: '',
      };
    case EVENT_DATA.FAIL:
      return {
        ...state,
        loading: false,
        eventData: [],
        error: action.payload,
      };

    case EVENT_DATA.LOAD_SUBTENANT:
      return {
        ...state,
        loading: true,
      };
    case EVENT_DATA.SUCCESS_SUBTENANT:
      return {
        ...state,
        loading: false,
        eventDataSubTenant: action.payload,
        error: '',
      };
    case EVENT_DATA.FAIL_SUBTENANT:
      return {
        ...state,
        loading: false,
        eventDataSubTenant:[],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default eventDataReducer;
