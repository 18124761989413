import { TENANT_PROFILE_DATA } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    success: false,
    tenantProfileData: {
        rows: [{
            textNumber: 'sms:',
        }]
   }
}

const tenantProfileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TENANT_PROFILE_DATA.LOAD:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case TENANT_PROFILE_DATA.SUCCESS:
            return {
                ...state,
                loading: false,
                tenantProfileData: action.payload,
                error: '',
                success: true,
            };
        case TENANT_PROFILE_DATA.FAIL:
            return {
                ...state,
                loading: false,
                tenantProfileData: [],
                error: action.error,
                success: false,
            }
        default:
            return state;
    }
}

export default tenantProfileReducer;
