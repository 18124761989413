import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { memo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import clsx from 'clsx';
import { CONSTS } from '../Common/CONSTS';
import SimpleImage from '../Component/SimpleImage';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // misc
    basicFont: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: '0.25px',
    },

    mt4: {
      marginTop: 4,
    },

    // component
    mainContainer: {
      paddingLeft: 20,
      paddingBottom: 100,
    },

    line: {
      width: '100%',
      height: 1,
      backgroundColor: '#000',
      marginBottom: 20,
    },

    heading: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'uppercase',
      marginBottom: 10,

      // media query
      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(2.5vw, 24px)',
        lineHeight: 'min(2.5vw, 24px)',
        margin: 'min(2.5vw, 20px) 0',
        paddingTop: 5,
      },
    },

    cards: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 20,
      gap: 20,
    },

    card: {
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.01)',
      },
      border: '1px solid rgba(0, 0, 0, 0.12)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: 4,
    },

    cardMedia: {},

    cardImgSkeleton: {
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
    },

    cardContent: {
      padding: '20px 22px',
    },

    title: {
      fontSize: 16,
      fontWeight: 700,

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        marginBottom: 8,
      },
    },

    subTitle: {
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        marginBottom: 5,
      },
    },

    learnMore: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
  }),
);

const CategoryListing = memo(() => {
  ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

  const classes = useStyles();
  const navigate = useNavigate();
  const sitemapData = useSelector((state: RootStateOrAny) => state.sitemapData);
  const { catName } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isSubTenant = query.get('isSubTenant');

  // find the required category

  const category = isSubTenant
    ? sitemapData.sitemapDataSubTenant.rows.find(
        (row) => row.sitemap[0]?.category?.slug === catName,
      )
    : sitemapData.sitemapData.rows.find(
        (row) => row.sitemap[0]?.category?.slug === catName,
      );

  const handleCardClick = (sitemapItem: any) => {
    const itemSlug = sitemapItem.slug;
    const link = isSubTenant
      ? `/category-landing/${itemSlug}?isSubTenant=true`
      : `/category-landing/${itemSlug}`;
    navigate(link);
  };

  if (!category) {
    return null;
  }

  return (
    <motion.div
      variants={CONSTS.TRANSITIONS.VARIANTS.DEFAULT_PAGE_VARIANTS}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={classes.mainContainer}
    >
      <div className={classes.line} />

      <Typography
        variant="h2"
        className={clsx(classes.basicFont, classes.heading)}
      >
        {category.name}
      </Typography>

      <div className={classes.cards}>
        {category.sitemap?.map((sitemapDataItem: any, index: number) => (
          <Card className={classes.card} key={index}>
            <CardActionArea onClick={() => handleCardClick(sitemapDataItem)}>
              <SimpleImage
                src={sitemapDataItem?.headerImage[0]?.downloadUrl}
                alt={sitemapDataItem?.title}
                className={classes.cardMedia}
                skeletonProps={{
                  className: classes.cardImgSkeleton,
                }}
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  variant="h4"
                  className={clsx(classes.basicFont, classes.title)}
                >
                  {sitemapDataItem?.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={clsx(
                    classes.basicFont,
                    classes.subTitle,
                    !sitemapDataItem?.restaurant?.cuisine && classes.mt4,
                  )}
                >
                  {sitemapDataItem?.restaurant?.cuisine}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={clsx(classes.basicFont, classes.learnMore)}
                >
                  Learn more
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </motion.div>
  );
});

export default CategoryListing;
