import Map, {
  MapRef,
  Marker,
  NavigationControl,
  GeolocateControl,
  Popup,
} from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import { Autocomplete, IconButton, TextField } from '@mui/material';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@mui/styles';
import LocateIcon from '@mui/icons-material/MyLocation';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

import { createAlert, debounce } from '../../Common/utils';
import { searchDataRequest } from '../../Redux/action/searchPlaceAction';
import { CONSTS } from '../../Common/CONSTS';
import { tenantUtils } from '../../Modules/Tenants/tenantSubdomain';
import axios from 'axios';
import maplibregl from 'maplibre-gl';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    mainContainer: {},

    searchContainer: {
      position: 'relative',
      padding: '0 24px',
      marginBottom: 24,
    },

    searchBox: {},

    optionItem: {
      padding: '12px !important',
    },

    mapContainer: {
      marginTop: 20,
      // backgroundColor: '#8eb26f',
      height: 'calc(100vh - 207.5px)',
      [theme.breakpoints.up('sm')]: {
        height: 'calc(100vh - 230px)',
      },
    },

    locateMeContainer: {
      position: 'absolute',
      zIndex: CONSTS.Z_INDEX.LEVEL_2.LAST,
      cursor: 'pointer',
      //right: 20,
      //bottom: 40,
      right: 10,
      top: 10,
      padding: 0,
    },

    locateMeIcon: {
      width: 40,
      height: 40,
      padding: 8,
      borderRadius: '50%',
      backgroundColor: 'white',
      color: 'black',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
    },

    sliderContainer: {
      display: 'none',
      position: 'absolute',
      zIndex: CONSTS.Z_INDEX.LEVEL_2.LAST,
      cursor: 'pointer',
      left: 20,
      bottom: 40,
      padding: 0,
      width: 100,
    },

    sliderValueLabel: {
      backgroundColor: 'black',
    },

    [".maplibregl-ctrl-group"]: {
      '& button': {
        width: 48,
        height: 48,
      }
    },
  }),
);

type UserLocation = {
  lat: number;
  lng: number;
  text: string;
};

type PopupInfo = {
  text: string;
  lat: number;
  lng: number;
};

export const ResortMap = () => {
  ReactGA4.send({ hitType: 'pageview', page: '/resortMap' });

  const mapRef = useRef<MapRef>();

  const classes = useStyles();
  const location: any = useLocation();

  const dispatch = useDispatch();
  const tenantData = useSelector((state: RootStateOrAny) => state.tenantData);
  const { subTenantData } = useSelector(
    (state: RootStateOrAny) => state.subTenantData,
  );

  const [markerData, setMarkerData] = useState({
    display: false,
    name: '',
    lat: 0,
    lng: 0,
  });

  const tenantId = tenantUtils.isSubTenantPresent
    ? subTenantData.parentTenant
    : tenantData.tenantData.id;

  const [isLoaded, setIsLoaded] = useState(false);

  const searchPlaceData = useSelector(
    (state: RootStateOrAny) => state.searchPlaceData,
  );

  const myLocation: any = useRef(null);
  const currentLocation: any = useRef(null);

  const startLat = tenantData?.tenantData?.settings?.centerLat || 33.687624;
  const startLng = tenantData?.tenantData?.settings?.centerLong || -116.31194;
  const defaultBearing = tenantData?.tenantData?.settings?.bearing || 0;

  const [tileUrl, setTileUrl] = useState('');
  const [userLocation, setUserLocation] = useState<UserLocation>(null);
  const [popupInfo, setPopupInfo] = useState<PopupInfo>(null);

  const mapLayer = tenantData?.tenantData?.settings?.mapLayer;

  useEffect(() => {
    if (tenantUtils.isSubTenantPresent) {
      handleSubTenantUrl();
    } else handleTenantUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubTenantUrl = async () => {
    if (!subTenantData?.settings?.tileUrl) {
      const { data } = await axios.get('/tenant/' + subTenantData.parentTenant);
      setTileUrl(data?.settings?.tileUrl);
    } else setTileUrl(subTenantData?.settings?.tileUrl);

    setIsLoaded(true);
  };
  const handleTenantUrl = () => {
    setTileUrl(tenantData?.tenantData?.settings?.tileUrl);
    setIsLoaded(true);
  };

  const onSearchChange = debounce((text: string) => {
    if (!text) {
      return;
    }
    dispatch(searchDataRequest({ tenantId, text }));
  }, 500);

  const onChangeLocation = ({ latLng, zoom = 18, locationName }: any) => {
    if (mapRef.current) {
      mapRef.current.flyTo({ center: latLng, zoom });
      setMarkerData({
        display: true,
        name: locationName,
        lat: latLng.lat,
        lng: latLng.lng,
      });
      setPopupInfo({
        lat: latLng.lat,
        lng: latLng.lng,
        text: locationName,
      });
    }

    const prevLatLng = currentLocation.current?.getLatLng();
    const newLatLng = latLng;

    // remove previous marker if exists
    if (prevLatLng) {
      if (
        prevLatLng.lat === newLatLng.lat &&
        prevLatLng.lng === newLatLng.lng
      ) {
        return;
      }
    }
  };

  //This block has been replaced the geolocate control from maplibre
  const onLocateMe = () => {
    maplibreLoacateMe();
  };

  const maplibreLoacateMe = () => {
    const defaultLatLong = [
      tenantData?.tenantData?.settings?.centerLat || 33.687624,
      tenantData?.tenantData?.settings?.centerLong || -116.31194,
    ];

    setUserLocation(null);
    setPopupInfo(null);

    navigator.geolocation.getCurrentPosition(function (position) {
      const lng = position.coords.longitude;
      const lat = position.coords.latitude;

      const newLatLng = { lat, lng };

      const mapBounds = new maplibregl.LngLatBounds();
      mapBounds.extend({ lng: -116.316041, lat: 33.685328 });
      mapBounds.extend({ lng: -116.307839, lat: 33.689919 });

      // check if the location is within the bounds

      if (mapBounds.contains(newLatLng)) {
        setUserLocation({ ...newLatLng, text: 'You are here!' });
        setPopupInfo({
          ...newLatLng,
          text: 'You are here!',
        });
        mapRef.current.flyTo({ center: newLatLng, zoom: 18 });
      } else {
        mapRef.current.flyTo({
          center: [defaultLatLong[1], defaultLatLong[0]],
          zoom: 18,
        });
        createAlert({
          type: 'warning',
          message: 'You are outside the resort',
        });
      }
    });

    if (!navigator.geolocation) {
      createAlert({
        type: 'warning',
        message: 'Geolocation is not supported by your browser',
      });
      return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const { name, latLng, zoom } = location.state || {};
      if (latLng?.lat && mapRef.current) {
        onChangeLocation({ latLng, zoom, locationName: name });
      }
    }, 100);
  }, [location, mapRef.current]);

  //This block checks if the user has allowed the location access
  const handleGeolocation = () => {
    console.log("Geolocation testing......  ");
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
      createAlert({
        type: 'warning',
        message: 'Geolocation is not supported by your browser',
      });
      return;
    }
  }


  const renderSearch = () => {
    return (
      <div className={classes.searchContainer}>
        <Autocomplete
          freeSolo
          autoHighlight
          options={searchPlaceData.searchData?.rows}
          getOptionLabel={(option: any) =>
            option.name || option?.number + ' - ' + option?.roomName
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Map"
              placeholder="Room # or Location Name"
            />
          )}
          onChange={(e, value) => {
            if (!value) {
              return;
            }

            onChangeLocation({
              latLng: { lat: value.latitude, lng: value.longitude },
              locationName:
                value.name || value?.number + '-' + value?.roomName || '',
            });
          }}
          classes={{
            option: classes.optionItem,
          }}
        />
      </div>
    );
  };

  return (
    <div className={classes.mainContainer}>
      {renderSearch()}

      <div className={classes.mapContainer}>
        <Map
          ref={mapRef}
          initialViewState={{
            longitude: startLng,
            latitude: startLat,
            zoom: 18,
            bearing: defaultBearing,
          }}
          style={{ height: '100%', width: '100%' }}
          mapStyle={{
            version: 8,
            sources: {
              osm: {
                type: 'raster',
                tiles: ['https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'],
                tileSize: 256,
                //attribution: '&copy; OpenStreetMap Contributors',
                maxzoom: 19,
              },
              customRaster: {
                type: 'raster',
                tiles: [
                  tileUrl,
                ],
                tileSize: 256,
              },
            },
            layers: [
              {
                id: 'osm',
                type: 'raster',
                source: 'osm', // This must match the source key above
              },
              {
                id: 'customRaster',
                type: 'raster',
                source: 'customRaster', // This must match the source key above
                paint: {
                  'raster-opacity': 0.85,
                },
              },
            ],
          }}
        >
          
          <GeolocateControl 
            position="top-right"
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            onGeolocate={handleGeolocation}
             />

          {userLocation && (
            <Marker
              longitude={userLocation.lng}
              latitude={userLocation.lat}
              anchor="top"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo(null);
                setPopupInfo({
                  text: userLocation.text,
                  lat: userLocation.lat,
                  lng: userLocation.lng,
                });
              }}
            ></Marker>
          )}

          {markerData.display && (
            <Marker
              longitude={markerData.lng}
              latitude={markerData.lat}
              anchor="top"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo(null);
                setPopupInfo({
                  text: markerData.name,
                  lat: markerData.lat,
                  lng: markerData.lng,
                });
              }}
            ></Marker>
          )}

          {popupInfo && (
            <Popup
              anchor="bottom"
              longitude={Number(popupInfo.lng)}
              latitude={Number(popupInfo.lat)}
              closeButton={false}
              offset={15}
            >
              <div>{popupInfo.text}</div>
            </Popup>
          )}

          
        </Map>
      </div>
    </div>
  );
};
