import { Button, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as NotFoundIllustration } from '../assets/illustrations/404.svg';
import { CONSTS } from '../Common/CONSTS';
import { createAlert } from '../Common/utils';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // component
    mainContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      paddingTop: 100,
      paddingBottom: 100,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 200,
        paddingBottom: 200,
      },
    },
    illustration: {
      width: '80%',
      height: '80%',
      minWidth: 300,
      minHeight: 300,
      margin: 'auto',
      display: 'block',
      marginBottom: 25,
    },
    title: {
      fontFamily: CONSTS.FONTS.PRIMARY,
      fontSize: 'clamp(18px, 3vw, 30px )',
      lineHeight: '28px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      textAlign: 'center',
      marginBottom: 'clamp(18px, 2.5vw, 40px )',
    },

    cta: {
      fontFamily: CONSTS.FONTS.PRIMARY,
      fontSize: 'clamp(15px, 3vw, 25px )',
    },
  }),
);

const NotFound = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    createAlert({
      type: 'error',
      message: 'Page not found',
    });
  }, []);

  return (
    <div className={classes.mainContainer}>
      <NotFoundIllustration className={classes.illustration} />

      <Typography className={classes.title}>Page not found</Typography>

      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.cta}
        onClick={() => navigate('/')}
      >
        Go to home
      </Button>

      <div className="app-alert">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnFocusLoss={false}
        />
      </div>
    </div>
  );
};

export default NotFound;
