import { getPromotionService,getSubTenantPromotionService } from "../Service";

export const getPromotionDataApi = async (action) => {
    try {
        const response = await getPromotionService(action);
        const data = response.data;
        return data
    } catch (error) {
        throw Error
    }
}

export const getSubTenantPromotionDataApi = async (action) => {
    try {
        const response = await getSubTenantPromotionService(action);
        const data = response.data;
        return data
    } catch (error) {
        throw Error
    }
}