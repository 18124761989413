import { getMenuService } from '../Service/menuService';

export const getMenuAPi = async () => {
  try {
    const response = await getMenuService();
    const data = await response.data;
    return data;
  } catch (Error) {
    throw Error;
  }
};
