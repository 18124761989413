import { memo } from 'react';
import ReactGA4 from 'react-ga4';
import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';

import { CONSTS } from '../../Common/CONSTS';
import Promotions from './Promotions';
import OnlineOrder from './OnlineOrder';
import Sitemaps from './Sitemaps';
import { motion } from 'framer-motion';
import SitemapsSubTenant from './SitemapsSubTenant';
import { tenantUtils } from '../../Modules/Tenants/tenantSubdomain';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // misc
    basicFont: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: '0.25px',
    },

    // component
    mainContainer: {
      paddingBottom: 75,
    },

    mapCta: {
      padding: '6px 8px',
      textAlign: 'left',
      margin: '10px 25px 20px 25px',
      width: 'calc(100% - 50px)',
      backgroundColor: theme.palette.primary.main,
      fontFamily: CONSTS.FONTS.PRIMARY,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '16px',
      height: 36,
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      borderRadius: 4,

      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(2vw, 18px)',
        lineHeight: 'min(2vw, 18px)',
      },
    },

    line: {
      width: '100%',
      height: 1,
      backgroundColor: '#000',
      marginLeft: 20,
      marginBottom: 20,
    },
  }),
);

const Home = memo(() => {
  ReactGA4.send({ hitType: 'pageview', page: '/home' });
  const classes = useStyles();
  const navigate = useNavigate();

  const renderMapCta = () => (
    <motion.div
      initial={{ opacity: 0, x: '-40%' }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: { delay: 0.1, duration: 0.5, ease: 'easeInOut' },
      }}
      viewport={{ once: true }}
    >
      <Button
        component={motion.div}
        transition={CONSTS.TRANSITIONS.TEST}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.5, ease: 'easeInOut' },
        }}
        whileHover={{ scale: 1.02 }}
        variant="contained"
        size="large"
        className={classes.mapCta}
        color="primary"
        startIcon={<PlaceIcon />}
        onClick={() => {
          navigate('/resort-map');
        }}
      >
        Find my room | Hotel map
      </Button>

      <div className={classes.line} />
    </motion.div>
  );

  return (
    <motion.div
      variants={CONSTS.TRANSITIONS.VARIANTS.DEFAULT_PAGE_VARIANTS}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={classes.mainContainer}
    >
      <Promotions />
      {renderMapCta()}
      {tenantUtils.isSubTenantPresent && <SitemapsSubTenant />}
      <OnlineOrder />
      <Sitemaps />
    </motion.div>
  );
});

export default Home;
