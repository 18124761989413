import axios from 'axios';
import { getLocalStorageItem } from '../Common/utils';
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

// Request interceptor
axios.interceptors.request.use(
  (config: any) => {
    // Do something before request is sent
    const Accept = 'Accept';
    const ContentType = 'Content-Type';
    const TenantIncluded = 'x-tenant-included';
    const SubTenant = 'subTenant';
    config.headers[Accept] = 'application/json';
    config.headers[ContentType] = 'application/json';
    config.headers[TenantIncluded] = 'true';
    config.headers[SubTenant] = getLocalStorageItem('subTenant')?.id || '';

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);
