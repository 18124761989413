import axios from 'axios';

export const getEventService = async (action) => {
  const tenantId = action.tenantId;
  return await axios.get(`tenant/${tenantId}/event/guest`);
};



export const getSubTenantEventService = async (action) => {
  const tenantId = action.tenantId;
  const subTenantId = action.subTenantId;

  return await axios.get(`/tenant/${tenantId}/event/guest`, {
    params: {
      filter: { subTenantId },
    },
  });
};