import React from 'react';
import ReactDOM from 'react-dom';

import {
  applyPolyfills,
  defineCustomElements,
} from '@mapsindoors/components/loader';

import { Provider } from 'react-redux';

import { store } from '../src/Redux/store/rootStore';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

applyPolyfills().then(() => {
  defineCustomElements();
});
