import { call, put } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects'

import { getSitemapBySlugDataApi } from '../../Api';
import { SITE_MAP_SLUG } from '../actionType';

const takeEvery: any = Eff.takeEvery;

export function* getSitemapBySlugData(action: any): any {
    try {
        const sitemapBySlugData: any = yield call(() => getSitemapBySlugDataApi(action.payload));
        yield put({ type: SITE_MAP_SLUG.SUCCESS, payload: sitemapBySlugData });
    } catch (e: any) {
        yield put({ type: SITE_MAP_SLUG.FAIL, error: e });
    }
}

export default function* watchSitemapBySlugSaga() {
    yield takeEvery(SITE_MAP_SLUG.LOAD, getSitemapBySlugData);
}