import clsx from 'clsx';
import moment from 'moment';
import { createStyles, makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left_arrow_2.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right_arrow_2.svg';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    isSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
  })
);
const WeekHeader = ({ goToDate, selectedDate }) => {
  const getWeekDays = () => {
    let startDate = moment(selectedDate).startOf('week');
    const endDate = moment(selectedDate).endOf('week');
    const weekDays = [];

    while (startDate <= endDate) {
      weekDays.push({
        label: moment(startDate).format('ddd D'),
        dateId: moment(startDate).format('YYYY-MM-DD'),
        day: moment(startDate).format('ddd'),
        date: startDate,
        isSelected: moment(startDate).isSame(selectedDate, 'day'),
      });

      // increment
      startDate = moment(startDate).add(1, 'days');
    }

    return weekDays;
  };

  const days = getWeekDays();
  const classes = useStyles();

  return (
    <>
      <div className="buttons">
        <LeftArrowIcon
          className="left-arrow button"
          onClick={() => goToDate(moment(selectedDate).subtract(1, 'week'))}
        />
        <button className="today button" onClick={() => goToDate(moment())}>
          Today
        </button>

        <RightArrowIcon
          className="right-arrow button"
          onClick={() => goToDate(moment(selectedDate).add(1, 'week'))}
        />
      </div>
      <div className="WeekHeader">
        <div className="days">
          <AnimatePresence exitBeforeEnter>
            {days.map(({ day, dateId, isSelected, label, date }) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.2,
                    ease: 'easeIn',
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.2,
                    ease: 'easeOut',
                  },
                }}
                key={dateId}
                className={`day day-${day}`}
                onClick={() => goToDate(date)}
              >
                <div className={clsx('value', isSelected && classes.isSelected)}>
                  {label}
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default WeekHeader;
