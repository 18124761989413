import axios from 'axios';

export const getSitemapService = async (action) => {
    const tenantId = action.tenantId;
    return await axios.get(`/tenant/${tenantId}/sitemap/guest`);
};


export const getSubTenantSitemapService = async (action) => {
    const tenantId = action.tenantId;
    const subTenantId = action.subTenantId;

    return await axios.get(`/tenant/${tenantId}/sitemap/guest`, {
        params: {
            filter: { subTenantId },
        },
    });
};
