import { call, put, takeEvery } from 'redux-saga/effects';
import { getMenuAPi } from '../../Api/menuApi';
import { MENU_DATA } from '../actionType/menuType';

export function* getMenuData() {
  try {
    const menuData = yield call(() => getMenuAPi());
    yield put({ type: MENU_DATA.SUCCESS, payload: menuData });
  } catch (e) {
    yield put({ type: MENU_DATA.FAIL, error: e });
  }
}

export default function* watchMenuSaga() {
  yield takeEvery(MENU_DATA.LOAD, getMenuData);
}
