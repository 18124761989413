import { getSitemapService, getSubTenantSitemapService } from "../Service";

export const getSitemapDataApi = async (action) => {
    try {
        const response = await getSitemapService(action);
        const data = response.data;
        return data
    } catch (error) {
        //throw Error
        throw error // throw the caught error
    }
}

export const getSubTenantSitemapDataApi = async (action) => {
    try {
        const response = await getSubTenantSitemapService(action);
        const data = response.data;
        return data
    } catch (error) {
        //throw Error
        throw error // throw the caught error
    }
}