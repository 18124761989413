import { SITE_MAP_SLUG } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    sitemapDataBySlug: []
}

const sitemapBySlugReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case SITE_MAP_SLUG.LOAD:
            return {
                ...state,
                loading: true
            };
        case SITE_MAP_SLUG.SUCCESS:
            return {
                ...state,
                loading: false,
                sitemapDataBySlug: action.payload,
                error: ''
            };
        case SITE_MAP_SLUG.FAIL:
            return {
                ...state,
                loading: false,
                sitemapDataBySlug: [],
                error: action.error
            }
        default:
            return state;
    }
}

export default sitemapBySlugReducers;
