import axios from 'axios';

export const getTenantService = async (tenantName: string) => {
  return await axios.get(`tenant/url?url=${tenantName}`);
};

export const getSubTenantService = async (payload: any) => {
  return await axios.get(`/subTenant/url/${payload.subTenant}/${payload.tenant}`,
  );
};
