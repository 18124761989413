import './Common/Interceptor';

import { CssBaseline, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import { RouterNavigation } from './Template/Navigation/RouterNavigation';

import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { CONSTS } from './Common/CONSTS';

// styles
import 'tiny-slider/dist/tiny-slider.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';
import { tenantDataRequest } from './Redux/action';
import { subTenantDataRequest } from './Redux/action/subTenantAction';
import { tenantUtils } from './Modules/Tenants/tenantSubdomain';


ReactGA.initialize(CONSTS.TRACKING_ID);
ReactGA4.initialize('G-8W204Z2SP4');

function App(): JSX.Element {
  const tenantData = useSelector((state: RootStateOrAny) => state.tenantData);
  const { subTenantData } = useSelector(
    (state: RootStateOrAny) => state.subTenantData,
  );

  const ThemeColors = tenantData?.tenantData?.settings?.theme.split(/-/);
  const primaryColor = ThemeColors?.[0];
  const secondaryColor = ThemeColors?.[1];

  const ThemeColorsSubTenant = subTenantData?.settings?.theme.split(/-/);

  const primaryColorSubTenant = ThemeColorsSubTenant?.[0];
  const secondaryColorSubTenant = ThemeColorsSubTenant?.[1];
  const dispatch = useDispatch();

  useEffect(() => {
    if (tenantUtils.isSubTenantPresent) {
      const subTenantUrl = tenantUtils.getSubDomains().subTenant;
      const tenantUrl = tenantUtils.getSubDomains().tenant;
      dispatch(
        subTenantDataRequest({ tenant: tenantUrl, subTenant: subTenantUrl }),
      );
    } else {
      const url = tenantUtils.getSubDomains().tenant;
      dispatch(tenantDataRequest(url));
    }
  }, [dispatch]);

  // todo: later we can add more theme colors & theme options
  const themeData = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main:
              ThemeColors !== undefined
                ? primaryColor === 'default'
                  ? '#333333'
                  : primaryColor
                : '#333333',
            /*main: primaryColor !== undefined ?  primaryColor  == 'default' ? '#2196f3' : primaryColor : '#2196f3',*/
          },
          secondary: {
            main:
              secondaryColor !== undefined
                ? secondaryColor === 'default'
                  ? '#f50057'
                  : secondaryColor
                : '#f50057',
          },
        },
        typography: {
          fontFamily: ['Inter', 'serif'].join(','),
        },
      }),
    [tenantData],
  );

  const themeSubTenant = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main:
              ThemeColorsSubTenant !== undefined
                ? primaryColorSubTenant === 'default'
                  ? '#333333'
                  : primaryColorSubTenant
                : '#333333',
            /*main: primaryColor !== undefined ?  primaryColor  == 'default' ? '#2196f3' : primaryColor : '#2196f3',*/
          },
          secondary: {
            main:
              secondaryColorSubTenant !== undefined
                ? secondaryColorSubTenant === 'default'
                  ? '#f50057'
                  : secondaryColorSubTenant
                : '#f50057',
          },
        },
        typography: {
          fontFamily: ['Inter', 'serif'].join(','),
        },
      }),
    [subTenantData],
  );

  return (
    <ThemeProvider
      theme={tenantUtils.isSubTenantPresent ? themeSubTenant : themeData}
    >
      <CssBaseline enableColorScheme />
      <RouterNavigation />
    </ThemeProvider>
  );
}

export default App;
