import { SITE_MAP } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    success: '',
    sitemapData: [],
    sitemapDataSubTenant: []

}

const sitemapReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SITE_MAP.LOAD:
            return {
                ...state,
                loading: true,
                success: '',
            };
        case SITE_MAP.SUCCESS:
            return {
                ...state,
                loading: false,
                sitemapData: action.payload,
                error: '',
                success: true,
            };
        case SITE_MAP.FAIL:
            return {
                ...state,
                loading: false,
                sitemapData: [],
                error: action.error,
                success: false,
            }


        case SITE_MAP.LOAD_SUBTENANT:
            return {
                ...state,
                success: ''
            };
        case SITE_MAP.SUCCESS_SUBTENANT:
            return {
                ...state,
                loading: false,
                sitemapDataSubTenant: action.payload,
                error: '',
                success: true,
            };
        case SITE_MAP.FAIL_SUBTENANT:
            return {
                ...state,
                loading: false,
                sitemapDataSubTenant: [],
                error: action.error,
                success: '',
            }
        default:
            return state;
    }
}

export default sitemapReducer;
