import { Typography, IconButton, Button, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { memo, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import TinySlider from 'tiny-slider-react';

import { menuDataRequest } from '../../Redux/action';
import ReactGA4 from 'react-ga4';
import { HeaderImage, SitemapRow } from '../../types';
import { CONSTS } from '../../Common/CONSTS';
import clsx from 'clsx';
import SimpleImage from '../../Component/SimpleImage';

import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as MapPinIcon } from '../../assets/icons/mapPin.svg';

import defaultHeaderImg from '../../assets/images/cuisine-3.jpg';
import { useViewportScroll, motion } from 'framer-motion';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // misc
    mt32: {
      marginTop: 32,
    },

    mt10: {
      marginTop: 10,
    },

    mmt15: {
      marginTop: -15,
    },

    hide: {
      display: 'none !important',
    },

    progressBar: {
      backgroundColor: theme.palette.primary.main,
      position: 'fixed',
      maxWidth: 768,
      minWidth: 320,
      margin: '0 auto',
      top: 0,
      left: 0,
      right: 0,
      height: 4,
      borderRadius: 10,
      transformOrigin: '0%',
      zIndex: CONSTS.Z_INDEX.LEVEL_2.LAST,
    },

    // component
    mainContainer: {
      paddingBottom: 75,
    },

    headerImgSkeleton: {
      // aspectRatio: '16/9',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
    },

    pageActions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > *:not(:last-child).MuiButtonBase-root::after': {
        content: '""',
        height: 24,
        width: 2,
        backgroundColor: 'gray',
        margin: '0 20px',
      },
    },

    pageActionBtn: {
      padding: 0,
      color: 'rgba(0, 0, 0, 0.54)',
      transition: 'all 0.3s ease-in-out',

      '& > a': {
        color: 'rgba(0, 0, 0, 0.54)',
        height: 32,
        transition: 'all 0.3s ease-in-out',
      },

      '&:hover': {
        color: 'rgba(0, 0, 0, 0.87)',

        '& > a': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },

    line: {
      height: 2,
      backgroundColor: 'rgba(0, 0, 0, 0.54)',
      margin: '10px 20px 20px 10px',
    },

    content: {
      padding: '0 20px',
      marginBottom: 20,
    },

    title: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 25,
    },

    body: {
      fontSize: 16,
    },

    informationSection: {
      backgroundColor: theme.palette.secondary.main + '3f',
      padding: 20,
    },

    infoTitle: {
      fontWeight: 700,
      fontSize: 16,
      textTransform: 'uppercase',
      marginBottom: 12,
    },

    infoSubSection: {
      // marginBottom: 25,
    },

    infoGroup: {
      marginBottom: 20,
    },

    infoSubSectionTitle: {
      fontWeight: 600,
      fontSize: 14,
      textTransform: 'uppercase',
    },

    infoSubSectionBody: {
      fontSize: 14,
      lineHeight: '17px',
    },

    linkButton: {
      color: '#333',
      fontWeight: 500,
      fontSize: 14,
      maxWidth: 240,
      width: '100%',
      height: 40,
      margin: '5px 0',
    },

    menus: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 240,
      width: '100%',
    },

    info2: {
      padding: 20,
    },
  }),
);

const CategoryLanding = memo(() => {
  ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
  const classes = useStyles();
  const { catName }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { scrollYProgress } = useViewportScroll();

  const sitemapData = useSelector((state: RootStateOrAny) => state.sitemapData);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isSubTenant = query.get('isSubTenant');
  const [showMapPinIcon, setShowMapPinIcon] = useState(false);
  
  // find the required category

  const menuData = useSelector(
    (state: RootStateOrAny) => state?.menuData?.menuData?.rows,
  );

  const [sitemap, setSitemap] = useState(null);
  const [menus, setMenus]: any = useState([]);

  // Find required data from sitemap data or redirect to category listings page if not found
  useEffect(() => {
    const sitemapRows = isSubTenant
      ? sitemapData.sitemapDataSubTenant.rows
      : sitemapData.sitemapData.rows;

    const requiredRow = sitemapRows
      .find((obj) =>
        obj.sitemap.some((sitemapObj) => sitemapObj.slug === catName),
      )
      .sitemap.find((sitemapObj) => sitemapObj.slug === catName);

    const isRestaurant = !!requiredRow.restaurant;

    setSitemap({
      ...requiredRow,
      isRestaurant,
    });

    dispatch(menuDataRequest());
  }, [catName, isSubTenant, sitemapData, dispatch]);

  // set menus for given place/restaurant
  useEffect(() => {
    if (!menuData || menuData.length === 0 || !sitemap) {
      return;
    }

    // TODO: add place information in BE
    if (!sitemap.isRestaurant && !sitemap.place) {
      return;
    }

    //

    const id = sitemap.isRestaurant ? sitemap.restaurant.id : sitemap.place?.id;

    const requiredMenus = menuData.filter((item) =>
      sitemap.isRestaurant
        ? id === item.restaurant?.id
        : id === item.place /*&& sitemap.title?.includes(item.name)*/,
    );
    
    setMenus(requiredMenus);
  }, [menuData, sitemap]);

  // Check if latitude and longitude are present 
  useEffect(() => {
    if (sitemap) {
      const { isRestaurant = false, place, restaurant } = sitemap;
      let latitude, longitude;
      
      // Destructuring to ensure data exists
      if (isRestaurant && restaurant && restaurant.location && restaurant.location.latitude !== undefined && restaurant.location.longitude !== undefined) {
        ({ latitude, longitude } = restaurant.location);
      } else if (place && place.latitude !== undefined && place.longitude !== undefined) {
        ({ latitude, longitude } = place);
      }
       
      // Check if latitude and longitude are both defined and not null
      if (latitude !== undefined && latitude !== null && longitude !== undefined && longitude !== null) {
        setShowMapPinIcon(true);
      } else {
        // Handle the case when latitude or longitude is missing or null
        setShowMapPinIcon(false);
      }
    }
  }, [sitemap]);
  

  const renderInformationSection = () => {
    let { isRestaurant, place, restaurant } = sitemap;
    const title = isRestaurant
      ? 'Restaurant Information'
      : 'Essential Information';

    // TODO: add place information in BE
    place = place ? place : {};

    const dressPolicy = isRestaurant
      ? restaurant.dressPolicy
      : place.dressPolicy;
    const operatingHours = isRestaurant
      ? restaurant.operatingHours
      : place.operatingHours;
    const reservationLink = isRestaurant
      ? restaurant.reservationLink
      : place.bookingLink;

    const subSections = [
      {
        title: 'Cuisine',
        body: sitemap.restaurant?.cuisine,
      },
      {
        title: 'Dress',
        body: dressPolicy,
      },
      {
        title: 'Hours of Operation',
        body: operatingHours,
      },
      {
        title: 'Reservations',
        body: reservationLink,
        isLink: true,
      },
    ];

    const isSectionEmpty = subSections.every(
      ({ body }: any) => !body || body === '',
    );
    const isMenuPresent = menus.length > 0;
    
    return (
      <div
        className={clsx(classes.informationSection, {
          [classes.hide]: isSectionEmpty && !isMenuPresent,
        })}
      >
        <Typography variant="h4" className={classes.infoTitle}>
          {title}
        </Typography>

        <div className={classes.infoSubSection}>
          {subSections.map(
            ({ title, body, isLink }) =>
              body && (
                <div key={title} className={classes.infoGroup}>
                  <Typography className={classes.infoSubSectionTitle}>
                    {title}:
                  </Typography>

                  {!isLink ? (
                    <Typography
                      className={classes.infoSubSectionBody}
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: body?.split('.').join('<br />'),
                      }}
                    />
                  ) : (
                    <Button
                      className={classes.linkButton}
                      variant="outlined"
                      href={body}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reserve Online
                    </Button>
                  )}
                </div>
              ),
          )}

          <div
            className={clsx(classes.menus, {
              [classes.hide]: !isMenuPresent,
              [classes.mmt15]: !isSectionEmpty,
            })}
          >
            <Typography className={classes.infoSubSectionTitle}>
              Menus:
            </Typography>

            {menus.map(({ menuFile, name }) => (
              <Button
                key={name}
                className={classes.linkButton}
                variant="outlined"
                href={menuFile[0]?.downloadUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {name}
              </Button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    sitemap && (
      <motion.div
        variants={CONSTS.TRANSITIONS.VARIANTS.DEFAULT_PAGE_VARIANTS}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={clsx(classes.mainContainer, 'CategoryLanding')}
      >
        <TinySlider settings={CONSTS.SLIDER_SETTINGS.CATEGORY_LANDING}>
          {sitemap.headerImage?.length > 0 ? (
            sitemap.headerImage.map(({ id, downloadUrl }: HeaderImage) => (
              <div key={id}>
                <SimpleImage
                  src={downloadUrl}
                  alt="banner"
                  width="100%"
                  skeletonProps={{
                    className: classes.headerImgSkeleton,
                  }}
                />
              </div>
            ))
          ) : (
            <div>
              <SimpleImage
                src={defaultHeaderImg}
                alt="banner"
                skeletonProps={{
                  className: classes.headerImgSkeleton,
                }}
              />
            </div>
          )}
        </TinySlider>

        <div
          className={clsx(
            classes.pageActions,
            // gap for slider indicators
            sitemap.headerImage?.length > 1 ? classes.mt32 : classes.mt10,
          )}
        >
          {/* todo: location not yet present inside restaurant*/}
          {showMapPinIcon && (
            <IconButton
              disableRipple={true}
              className={classes.pageActionBtn}
              onClick={() => {
                if (sitemap) {
                  const { isRestaurant, place, restaurant } = sitemap;
                  let latitude, longitude, name;

                  if (isRestaurant && restaurant && restaurant.location) {
                    ({ location: { latitude, longitude }, name } = restaurant);
                  } else if (place && place.latitude !== undefined && place.longitude !== undefined) {
                    ({ latitude, longitude } = place);
                    name = place.name;
                  } else {
                    // Handle the case when latitude or longitude is missing in place
                    setShowMapPinIcon(false);
                    return;
                  }

                  // Check if both latitude and longitude are valid
                  if (latitude !== undefined && longitude !== undefined) {
                    setShowMapPinIcon(true);
                    navigate('/resort-map', {
                      state: {
                        name,
                        latLng: {
                          lat: latitude,
                          lng: longitude,
                        },
                      },
                    });
                  } else {
                    // Handle the case when latitude or longitude is missing
                    setShowMapPinIcon(false);
                  }
                }
              }}
            >
              {showMapPinIcon && <MapPinIcon />}
            </IconButton>
          )}



          {sitemap.phone && (
            <IconButton disableRipple={true} className={classes.pageActionBtn}>
              <a href={`tel:${sitemap.phone}`}>
                <PhoneIcon />
              </a>
            </IconButton>
          )}
        </div>

        { (sitemap.phone || showMapPinIcon) && (
          <div className={classes.line} />
        )}
        

        {/*<div className={classes.content}>
          <Typography variant="h5" className={classes.title}>
            {sitemap?.title}
          </Typography>

          <div
            className={classes.body}
            dangerouslySetInnerHTML={{ __html: sitemap?.body }}
          />
        </div>*/}

        <Box sx={{ padding: 2, mb: '1.5rem' }}>
          <Typography variant="h5" gutterBottom>
            {sitemap?.title}
          </Typography>
          <Box
            sx={{ typography: 'body1', lineHeight: 1.5 }}
            dangerouslySetInnerHTML={{ __html: sitemap?.body }}
          />
        </Box>

        {renderInformationSection()}

        <div
          className={clsx(classes.info2, {
            [classes.hide]: !sitemap?.body2,
          })}
          dangerouslySetInnerHTML={{ __html: sitemap?.body2 }}
        />

        <motion.div
          className={classes.progressBar}
          style={{ scaleX: scrollYProgress }}
        />
      </motion.div>
    )
  );
});

export default CategoryLanding;
