import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CategoryListing from '../../Screen/CategoryListing';
import CategoryLanding from '../../Screen/CategoryLanding';
import NotFound from '../../Screen/NotFound';
import { ResortMap } from '../../Screen/ResortMap';
import Map from '../../Screen/ResortMap/MapPeople';
import Home from '../../Screen/Home';
import { RootStateOrAny, useSelector } from 'react-redux';
import React from 'react';
import useScrollToTop from '../../Common/hooks/useScrollToTop';
import { LayoutWithFooter } from '../LayoutWithFooter';
import Calendar from '../../Screen/Calendar';
import Event from '../../Screen/Event';

const AnimatedRoutes = () => {
  const location = useLocation();
  const sitemapData = useSelector((state: RootStateOrAny) => state.sitemapData);
  const sitemapRows = sitemapData.sitemapData.rows;
  const sitemapRowsSubTenant = sitemapData.sitemapDataSubTenant.rows;

  // Scroll to top on route change
  useScrollToTop();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path={'/'} element={<LayoutWithFooter />}>
          <Route path={'/'} element={<Home />} />
          <Route path="/calendar" element={<Calendar />} />
          {['/resort-map', '/map'].map((path) => (
            <Route key={path} path={path} element={<ResortMap />} />
          ))}
          <Route path={'/MapPeople'} element={<Map />} />

          {((sitemapRows && sitemapRows.length > 0) ||
            (sitemapRowsSubTenant && sitemapRowsSubTenant.length > 0)) && (
            <>
              <Route
                path={'/site-map/:catName'}
                element={<CategoryListing />}
              />
              <Route
                path={'/category-landing/:catName'}
                element={<CategoryLanding />}
              />
            </>
          )}

          <Route element={<Event />} path={'event/:eventId'} />
        </Route>

        {/* 404 */}
        <Route path="/404" element={<NotFound />} />

        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
