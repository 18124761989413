import { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { sitemapDataRequest } from '../../Redux/action/sitemapAction';

import ReactGA from 'react-ga';
import { GridLoader } from 'react-spinners';

import { CONSTS } from '../../Common/CONSTS';
import AnimatedRoutes from './AnimatedRoutes';
import Title from '../../Screen/errorPage';

ReactGA.initialize(CONSTS.TRACKING_ID);

const RouterNavigation = () => {
  const dispatch = useDispatch();
  const tenantData = useSelector((state: RootStateOrAny) => state.tenantData);
  const subTenantData = useSelector((state: RootStateOrAny) => state.subTenantData);
  const sitemapData = useSelector((state: RootStateOrAny) => state.sitemapData);
  const sitemapRows = sitemapData.sitemapData.rows;

  useEffect(() => {
    dispatch(sitemapDataRequest());
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // show loader if sitemap is not loaded
  console.log('tenant', tenantData);
  console.log('subtenant:', subTenantData);
  console.log('sucess', sitemapData.success);
  
  return tenantData.tenantData === 'no data found' || subTenantData.subTenantData === 'no data found' ? (
    <Title message={'Partner Not Found'}/>
  ) : sitemapRows?.length > 0 ? (
    <Router>
      <AnimatedRoutes />
    </Router>
  ) : (
    <div className="loader-wrapper">
      <GridLoader size={15} margin={2} color={'#6DE2CB'} />
    </div>
  );

};

export { RouterNavigation };
