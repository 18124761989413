// This will test whether run in localhost
// If localhost, set host and protocol appropriately

import { CONSTS } from '../../Common/CONSTS';

// All others use nektar.to global
const frontendUrl =
  process.env.REACT_APP_ENVIRONMENT === 'localhost'
    ? {
        host: 'localhost:3000',
        protocol: 'http',
      }
    : {
        host: 'nektar.to',
        protocol: 'https',
      };

export const tenantUtils = {
  get isTenantPresent() {
    return Boolean(this.getSubDomains().tenant);
  },

  get isSubTenantPresent() {
    return Boolean(this.getSubDomains().subTenant);
  },

  getSubDomains() {
    const subDomains = {
      tenant: '',
      subTenant: '',
    };
    const hosts = window.location.host.split('.');

    // Check if the first sub-domain is forbidden
    if (CONSTS.FORBIDDEN_SUBDOMAINS.includes(hosts[0])) {
      hosts.shift();
    }

    const currentHostDots = hosts.length;
    const defaultDots = frontendUrl.host.split('.').length;

    // tenant only
    if (currentHostDots === defaultDots + 1) {
      subDomains.tenant = hosts[0];
    }
    // tenant + subTenant only
    else if (currentHostDots === defaultDots + 2) {
      subDomains.subTenant = hosts[0];
      subDomains.tenant = hosts[1];
    }
    // no sub-domain is present -- defaulting to laquintaresort for now due to CNAME issue
    else if (currentHostDots <= defaultDots) {
      subDomains.tenant = 'laquintaresort';
    }

    return subDomains;
  },
};
