import { getSubTenantService, getTenantService } from '../Service';

export const getTenantDataApi = async (tenantName: string) => {
  try {
    const response = await getTenantService(tenantName);
    let data = response.data;
    if (!data?.name) {
      data = 'no data found';
    }
    return data;
  } catch (error) {
    throw Error;
  }
};

export const getSubTenantByUrl = async (payload: any) => {
  try {
    const response = await getSubTenantService(payload);
    let data = response.data;
    if (!data?.name) {
      data = 'no data found';
    }
    return data;
  } catch (error) {
    throw Error;
  }
};
