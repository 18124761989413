import React from 'react'
import { makeStyles } from '@mui/styles'
import { Container, Box } from '@mui/material'

const createStyles = (theme) => ({
    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#000',
        overflow: 'hidden',
        padding: '0',
    },

    message: {
      color: '#fff',
      display: 'block',
      marginTop: '1rem',
      maxWidth: '80%',
    },

    title: {
        fontFamily: '"Avant Garde Pro Book", sans-serif',
        fontFeatureSettings: '"salt" 2',
        fontSize: '20vmin',
        maxWidth: '80%',
        color: '#000',
        textShadow: '0px 0px 3vw #5f4b8b',
        animation: `$glowEffect 7s infinite alternate linear`,
      },
    
      '@keyframes glowEffect': {
        '0%': {
          textShadow: '0px 0px 3vw #5f4b8b',
        },
        '100%': {
          textShadow: '0px 0px 3vw #5ac5c4',
        },
      },
})
const useStyles = makeStyles(createStyles);

const Title = ({ message }) => {
  const classes = useStyles();

  return (
    <Container disableGutters={true}>
      <Box className={classes.errorContainer} display="flex" flexDirection="column">
        <h1 className={classes.title}>nektar</h1>
        <h3 className={classes.message}>{message}</h3>
      </Box>
    </Container>
  );
}

export default Title;
