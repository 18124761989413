import { call, put, takeEvery } from 'redux-saga/effects';

import { getSubTenantByUrl } from '../../Api';
import { setLocalStorageItem } from '../../Common/utils';
import {
  PROMOTION_DATA,
  SITE_MAP,
  SUBTENANT_DATA,
  EVENT_DATA,
  TENANT_PROFILE_DATA,
  ONLINE_ORDER_DATA,
  SEARCH_PLACE,
} from '../actionType';
import { getTenantService } from '../../Service';

export function* getSubTenant(action: any): any {
  try {

    const tenantData: any = yield call(() =>
      getTenantService(action.payload.tenant),
    );


    const subTenantData: any = yield call(() =>
      getSubTenantByUrl({ subTenant: action.payload.subTenant, tenant: tenantData.data.id }),
    );
    yield put({ type: SUBTENANT_DATA.SUCCESS, payload: subTenantData });
    if (subTenantData?.id) {
      setLocalStorageItem('subTenantId', {
        id: subTenantData?.id,
        name: subTenantData?.name || 'Resort',
      });

      // SubTenant
      yield put({ type: PROMOTION_DATA.LOAD_SUBTENANT, payload: { subTenantId: subTenantData.id, tenantId: subTenantData.parentTenant } });
      yield put({ type: EVENT_DATA.LOAD_SUBTENANT, payload: { subTenantId: subTenantData.id, tenantId: subTenantData.parentTenant } });
      yield put({ type: SITE_MAP.LOAD_SUBTENANT, payload: { subTenantId: subTenantData.id, tenantId: subTenantData.parentTenant } });



      // Tenant
      yield put({ type: TENANT_PROFILE_DATA.LOAD, payload: subTenantData.parentTenant });
      yield put({ type: ONLINE_ORDER_DATA.LOAD, payload: subTenantData.parentTenant });
      yield put({ type: SITE_MAP.LOAD, payload: { tenantId: subTenantData.parentTenant } });
      yield put({ type: PROMOTION_DATA.LOAD, payload: { tenantId: subTenantData.parentTenant } });
      yield put({ type: EVENT_DATA.LOAD, payload: { tenantId: subTenantData.parentTenant } });

      // added to preload all geolocations for wayfinding
      yield put({ type: SEARCH_PLACE.LOAD, payload: { tenantId: subTenantData.parentTenant } });




    }
  } catch (e: any) {
    yield put({ type: SUBTENANT_DATA.FAIL, error: e });
  }
}

export default function* watchTenantData() {
  yield takeEvery(SUBTENANT_DATA.LOAD, getSubTenant);
}
