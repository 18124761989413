import { Typography, Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { memo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import ReactGA4 from 'react-ga4';
import clsx from 'clsx';
import { CONSTS } from '../Common/CONSTS';
import SimpleImage from '../Component/SimpleImage';
import { motion } from 'framer-motion';
import defaultHeaderSrc from '../assets/images/eventDefaultHeader.png';
import eventAvatarSvg from '../assets/icons/event_avatar.svg';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // misc
    basicFont: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: '0.25px',
    },

    hide: {
      display: 'none !important',
    },

    // component
    mainContainer: {
      paddingBottom: 100,
    },

    header: {
      marginBottom: 28,
    },

    headerImg: {
      aspectRatio: '16/9',
      marginBottom: 28,
    },

    headerImgSkeleton: {
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
    },

    body: {
      padding: '0 20px',
    },

    heading: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '19px',
      textTransform: 'uppercase',
      marginBottom: 11,
    },

    instructor: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      marginBottom: 9,
    },

    imgContainer: {
      marginTop: 3,
      marginLeft: -4,
      padding: 4,
      border: '1px solid #58534f',
      borderRadius: '50%',
    },

    avatar: {
      width: 40,
      height: 40,
      borderRadius: '50%',
    },

    avatarSkeleton: {
      width: 40,
      height: 40,
    },

    instructorInfo: {
      lineHeight: '17px',
      gap: 2,
    },

    description: {
      marginBottom: 10,
    },

    informationSection: {
      backgroundColor: theme.palette.secondary.main + '3f',
      padding: 20,
      margin: '0 -20px',
    },

    infoTitle: {
      fontWeight: 700,
      fontSize: 16,
      textTransform: 'uppercase',
      marginBottom: 12,
    },

    infoGroup: {
      marginBottom: 20,
    },

    infoSubSectionTitle: {
      fontWeight: 700,
      fontSize: 14,
      textTransform: 'uppercase',
    },

    infoSubSectionBody: {
      fontSize: 14,
      lineHeight: '17px',
    },

    linkButton: {
      color: '#333',
      fontWeight: 500,
      fontSize: 14,
      maxWidth: 240,
      width: '100%',
      height: 40,
      margin: '5px 0',
    },
  }),
);

const Event = memo(() => {
  ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

  const { eventId } = useParams();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isSubTenant = query.get('isSubTenant');

  const filter = isSubTenant ? 'eventDataSubTenant' : 'eventData';

  const eventData = useSelector(
    (state: RootStateOrAny) => state.eventData[filter],
  );

  const classes = useStyles();

  const event = eventData?.rows?.find((item: any) => item.id === eventId);

  if (!event) {
    return null;
  }

  const startTime = moment(event.startDate).format('h:mmA');
  const endTime = moment(event.endDate).format('h:mmA');
  const location = event.location?.name;
  const { price, notes, bookingLink, bookingLabel } = event;

  const infoSection = [
    {
      title: 'Time',
      value: `${startTime} - ${endTime}`,
    },
    {
      title: 'Location',
      value: location,
    },
    {
      title: 'Cost',
      value: price,
    },
    {
      title: 'Notes',
      value: notes,
    },
  ];

  const isSectionEmpty = infoSection.every(
    ({ value }: any) => !value || value === '',
  );

  return (
    <motion.div
      variants={CONSTS.TRANSITIONS.VARIANTS.DEFAULT_PAGE_VARIANTS}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={classes.mainContainer}
    >
      <div className={classes.header}>
        <SimpleImage
          src={event?.headerImage[0]?.downloadUrl || defaultHeaderSrc}
          alt={event?.headerImage[0]?.name}
          skeletonProps={{
            className: classes.headerImgSkeleton,
          }}
          className={classes.headerImg}
        />
      </div>
      <div className={classes.body}>
        <Typography
          variant="h2"
          className={clsx(classes.basicFont, classes.heading)}
        >
          {event?.title}
        </Typography>

        {/* Instructor Section */ event?.host && (
          <div className={classes.instructor}>
            <div className={classes.imgContainer}>
              <SimpleImage
                src={event?.host.headshot[0]?.downloadUrl || eventAvatarSvg}
                alt="event avatar"
                className={classes.avatar}
                skeletonProps={{
                  className: classes.avatarSkeleton,
                }}
              />
            </div>
            <div className={clsx(classes.basicFont, classes.instructorInfo)}>
              <p>{event?.host.name}</p>
              <p style={{ color: '#555' }}>{event?.host.title}</p>
            </div>
          </div>
        )}

        <div className={classes.description}>{event?.description}</div>

        <div
          className={clsx(classes.informationSection, {
            [classes.hide]: isSectionEmpty,
          })}
        >
          <Typography variant="h4" className={classes.infoTitle}>
            Essential Information
          </Typography>

          {infoSection.map(
            ({ title, value }) =>
              value && (
                <div key={title} className={classes.infoGroup}>
                  <Typography className={classes.infoSubSectionTitle}>
                    {title}:
                  </Typography>

                  <Typography
                    className={classes.infoSubSectionBody}
                    variant="body2"
                  >
                    {value}
                  </Typography>
                </div>
              ),
          )}
          {/* Conditional Button Rendering */}
          {bookingLink && (
            <Button
              variant="outlined"
              color="primary"
              href={bookingLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.linkButton}
            >
              {bookingLabel || 'Book Now'}
            </Button>
          )}
        </div>
      </div>
    </motion.div>
  );
});

export default Event;
