import { SEARCH_PLACE } from '../actionType';

export const searchDataRequest = (payload: any) => ({
  type: SEARCH_PLACE.LOAD,
  payload: payload,
});

export const searchDataRequestAll = () => ({
  type: SEARCH_PLACE.LOAD_ALL,
});
