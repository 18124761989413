import { ONLINE_ORDER_DATA } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    onlineOrderData: {}
}

const onlineOrderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ONLINE_ORDER_DATA.LOAD:
            return {
                ...state,
                loading: true
            };
        case ONLINE_ORDER_DATA.SUCCESS:
            return {
                ...state,
                loading: false,
                onlineOrderData: action.payload,
                error: ''
            };
        case ONLINE_ORDER_DATA.FAIL:
            return {
                ...state,
                loading: false,
                onlineOrderData: [],
                error: action.error
            }
        default:
            return state;
    }
}

export default onlineOrderReducer;
