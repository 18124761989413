import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { CONSTS } from '../../Common/CONSTS';
import { motion } from 'framer-motion';
import { RootStateOrAny, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment';
import listPlugin from '@fullcalendar/list';
import eventAvatarSvg from '../../assets/icons/event_avatar.svg';
import WeekHeader from './WeekHeader';
import { getSundayOfWeek } from '../../Common/utils';

const getFormattedWeek = (days: string[]) =>
  days.map((day) => CONSTS.MAPPING.DAY_TO_NUMBER[day]);

const Calendar = memo(() => {
  ReactGA4.send({ hitType: 'pageview', page: '/calendar' });
  const calendarRef = useRef(null);
  const navigate = useNavigate();

  const { eventData, eventDataSubTenant } = useSelector(
    (state: RootStateOrAny) => state.eventData,
  );

  const eventsListing = [
    ...(eventData?.rows ?? []),
    ...(eventDataSubTenant?.rows ?? []),
  ];

  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const initStartDate = getSundayOfWeek();

  useEffect(() => {
    const newEvents = [];

    if (eventsListing?.length > 0) {
      newEvents.push({
        id: 'noEvents',
        title: 'No Events',
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        className: 'noEvents',
        extendedProps: {
          noEvent: true,
        },
      });

      eventsListing?.forEach((event) => {
        let newEvent = {};

        if (event?.subTenant) {
          newEvent = { extendedProps: { subTenant: event.subTenant } };
        }
        if (event.endRecur) {
          const startDate = new Date(event.startDate);
          const startTime = new Date(event.startDate).toTimeString();
          const endTime = new Date(event.endDate).toTimeString();
          const endDate = new Date(event.endRecur);

          newEvent = {
            id: event.id,
            title: event.title,
            startRecur: startDate,
            endRecur: endDate,
            startTime: startTime,
            endTime: endTime,
            daysOfWeek: getFormattedWeek(event.dayOfWeek),
            extendedProps: {
              price: event.price,
              location: event.location,
              url: event.url, //todo: booking link?
              hostImg: event.host?.headshot[0]?.downloadUrl || eventAvatarSvg,
              host: event.host,
              ...newEvent,
            },
          };
        } else {
          // alert('test');
          // TODO: LATER
          newEvent = {
            title: event.title,
            start: event.startDate,
            end: event.endDate,
            id: event.id,
            extendedProps: {
              ...newEvent,
            },
          };
        }

        newEvents.push(newEvent);
      });

      setEvents(newEvents);
    }
    // add default event as "No Events"
  }, [eventsListing.length]);


  const goToDate = useCallback((date) => {
    const momDate = moment(date).format('YYYY-MM-DD');
    // Revised from eventsList-date to data-date ... seems plugin revised
    const dateEl = document.querySelector(`[data-date="${momDate}"]`);
    const wrapper = document.querySelector('.fc-scroller.fc-scroller-liquid');

    if (!dateEl || !wrapper) {
      return;
    }

    setSelectedDate(date);

    const { top } = dateEl.getBoundingClientRect();
    const topOffset = wrapper.scrollTop - wrapper.getBoundingClientRect().top;
    const reqScroll = top + topOffset;

    wrapper.scrollTo({
      top: reqScroll,
      behavior: 'smooth',
    });
  }, []);

  const renderEventContent = (eventInfo) => {
    const startTime = moment(eventInfo.event.start).format('h:mma');
    const endTime = moment(eventInfo.event.end).format('h:mma');
    const { noEvent, hostImg, host, location, price } =
      eventInfo.event.extendedProps;

    return (
      <div className={`eventContent ${noEvent ? 'noEvent' : ''}`}>
        {noEvent ? (
          <div className="noEventText">{eventInfo.event.title}</div>
        ) : (
          <>
            <div className="eventContent_img">
              <img src={hostImg} alt="instructor" />
            </div>

            <div className="eventContent_info">
              <h2 className="title">
                {eventInfo.event.title}

                {location?.name && (
                  <span className="location"> - {location.name}</span>
                )}
              </h2>
              <p className="time">
                {startTime} - {endTime}
                {host?.name && ` - ${host.name}`}
              </p>
              <p className="price">{price}</p>
            </div>
          </>
        )}
      </div>
    );
  };

  // todo: use isLoading param of reduxStore once implemented
  return (
    <motion.div
      variants={CONSTS.TRANSITIONS.VARIANTS.DEFAULT_PAGE_VARIANTS}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="Calendar"
    >
      <WeekHeader goToDate={goToDate} selectedDate={selectedDate} />

      <FullCalendar
        ref={calendarRef}
        viewClassNames="FullCalendar_view"
        events={events}
        plugins={[listPlugin]}
        initialView="listAll"
        eventContent={renderEventContent}
        navLinks={true}
        navLinkDayClick={(date) => {
          goToDate(date);
        }}
        listDayFormat={{
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        }}
        listDaySideFormat={false}
        views={{
          listAll: {
            type: 'listYear',
            duration: { months: 3 },
            initialDate: initStartDate,
          },
        }}
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        // doesn't take precedence over views.listAll.duration
        // duration={{ weeks: 1 }}
        validRange={{
          start: initStartDate,
          end: moment(initStartDate)
            .add(9, 'weeks')
            .subtract(1, 'day')
            .toDate(),
        }}
        eventClick={({ event }) => {
          const id = event.id;
          const subTenant =
            event.extendedProps?.extendedProps?.subTenant || false;

          if (id === 'noEvents') {
            return;
          }
          navigate(`/event/${id}${subTenant ? '?isSubTenant=true' : ''}`);
        }}
      />
    </motion.div>
  );
});

export default Calendar;
