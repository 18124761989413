import { memo, useState } from 'react';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import { motion } from 'framer-motion';

interface Props {
  src: string;
  alt: string;
  className?: string;
  skeletonProps?: any;
  [key: string]: any;
}

const defaultProps = {
  className: '',
  skeletonProps: {},
};

const SimpleImage: React.FC<Props> = memo(
  ({ src, alt, className, skeletonProps, forceLoad, ...props }) => {
    const [isLoaded, setIsLoaded] = useState(!!forceLoad);

    return (
      <>
        <motion.img
          src={src}
          onLoad={() => setIsLoaded(true)}
          className={clsx(!isLoaded && 'height-0', className)}
          alt={alt}
          {...props}
        />
        {!isLoaded && (
          <Skeleton
            variant="rectangular"
            width="100%"
            animation="wave"
            {...skeletonProps}
          />
        )}
      </>
    );
  },
);

export default SimpleImage;

SimpleImage.defaultProps = defaultProps;
