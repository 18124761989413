import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import { CONSTS } from '../../Common/CONSTS';
import SimpleImage from '../../Component/SimpleImage';
import defaultHeaderSrc from '../../assets/images/eventDefaultHeader.png';

import { SitemapRow } from '../../types';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // component
    mainContainer: {},

    categorySection: {
      marginBottom: 20,
      marginLeft: 20,
    },

    categoryTitle: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      textTransform: 'uppercase',
      marginBottom: 20,
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      position: 'relative',
      maxWidth: 'max-content',

      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: -2,
        left: '50%',
        width: 0,
        height: 2,
        backgroundColor: 'black',
        transition: 'all 0.6s',
      },

      '&:hover': {
        '&::before': {
          left: 0,
          width: '100%',
        },
      },

      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(2.5vw, 24px)',
        lineHeight: 'min(2.5vw, 24px)',
        paddingTop: 'min(2.5vw, 10px)',
      },
    },

    line: {
      width: '100%',
      height: 1,
      backgroundColor: '#000',
      marginBottom: 20,
      marginTop: 20,
    },

    slider: {
      '& .tns-outer': {
        marginBottom: -15,
      },
      '& .tns-ovh': {
        width: 'calc(100% + 30px)',
        padding: '0 15px 15px 15px',
        marginLeft: -15,
      },
    },

    categoryCard: {
      aspectRatio: '5/7',
      borderRadius: 10,
      marginRight: 16,
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },

    categoryImg: {
      aspectRatio: '5/7',
      objectFit: 'cover',
    },

    categoryImgSkeleton: {
      paddingTop: 'calc(100% * 7 / 5)',
      width: '100%',
    },

    categoryText: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      color: 'white',
      padding: 12,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    },

    categoryTextTitle: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(3vw, 20px)',
        lineHeight: 'min(3vw, 20px)',
      },
    },
  }),
);

const Sitemaps = memo(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const sitemapData = useSelector((state: RootStateOrAny) => state.sitemapData);

  const sitemapRows = sitemapData?.sitemapData?.rows;
  const handleCardClick = (sitemapItem: any) => {
    const itemSlug = sitemapItem.slug;
    navigate(`/category-landing/${itemSlug}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 400 }}
      animate={{
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.5,
          duration: 0.5,
        },
      }}
      className={classes.mainContainer}
    >
      {sitemapRows.map(({ name, sitemap }: SitemapRow, rowIndex) => (
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              // delay: rowIndex === 0 ? 0.25 : 0,
              duration: 0.5,
              ease: 'easeInOut',
            },
          }}
          viewport={{ once: true, amount: 0.3 }}
          key={name}
          className={classes.categorySection}
        >
          <Typography
            variant="h5"
            className={classes.categoryTitle}
            onClick={() => navigate(`/site-map/${sitemap[0]?.category?.slug}`)}
          >
            {name}
          </Typography>

          <div className={classes.slider}>
            <TinySlider settings={CONSTS.SLIDER_SETTINGS.HOME_CATEGORY}>
              {sitemap?.map((sitemapDetailData: any, index: number) => {
                return (
                  <Card className={classes.categoryCard} key={index} raised>
                    <CardActionArea
                      onClick={() => handleCardClick(sitemapDetailData)}
                    >
                      <SimpleImage
                        transition={CONSTS.TRANSITIONS.TEST}
                        whileHover={{ scale: 1.05 }}
                        loading={index >= 1 ? 'eager' : 'lazy'}
                        src={
                          sitemapDetailData.headerImage[0]?.downloadUrl ||
                          defaultHeaderSrc
                        }
                        alt={sitemapDetailData?.title || 'category image'}
                        width="100%"
                        height="100%"
                        className={classes.categoryImg}
                        skeletonProps={{
                          className: classes.categoryImgSkeleton,
                        }}
                      />

                      <Box className={classes.categoryText}>
                        <Typography
                          variant="body1"
                          className={classes.categoryTextTitle}
                        >
                          {sitemapDetailData?.title}
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                );
              })}
            </TinySlider>
          </div>

          {rowIndex !== sitemapRows.length - 1 && (
            <div className={classes.line} />
          )}
        </motion.div>
      ))}
    </motion.div>
  );
});

export default Sitemaps;
