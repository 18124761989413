import { getAllPlaceService, getSearchPlaceService } from '../Service';

export const getSearchPlaceApi = async (payload: any) => {
  try {
    const response = await getSearchPlaceService(payload);
    const data = response.data;
    return data;
  } catch (error) {
    throw Error;
  }
};

export const getAllPlaceApi = async () => {
  try {
    const response = await getAllPlaceService();
    const data = response.data;
    return data;
  } catch (error) {
    throw Error;
  }
};
