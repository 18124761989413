import { call, put, takeEvery } from 'redux-saga/effects';

import { getTenantProfileDataApi } from '../../Api';
import { TENANT_PROFILE_DATA } from '../actionType';

export function* getTenantProfileData(action: any): any {
    try {
        const tenantProfileData: any = yield call(() => getTenantProfileDataApi(action.payload));
        yield put({ type: TENANT_PROFILE_DATA.SUCCESS, payload: tenantProfileData });
    } catch (e: any) {
        yield put({ type: TENANT_PROFILE_DATA.FAIL, error: e });
    }
}

export default function* watchTenantProfileData() {
    yield takeEvery(TENANT_PROFILE_DATA.LOAD, getTenantProfileData);
}