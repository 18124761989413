import { call, put, takeEvery } from 'redux-saga/effects';

import { getSitemapDataApi, getSubTenantSitemapDataApi } from '../../Api';
import { SITE_MAP } from '../actionType';

export function* getSitemapData(action: any): any {
    try {
        const sitemapData: any = yield call(() => getSitemapDataApi(action.payload));
        yield put({ type: SITE_MAP.SUCCESS, payload: sitemapData });
    } catch (e: any) {
        yield put({ type: SITE_MAP.FAIL, error: e });
    }
}
export function* getSubTenantSitemapData(action: any): any {
    try {
        const sitemapData: any = yield call(() => getSubTenantSitemapDataApi(action.payload));
        yield put({ type: SITE_MAP.SUCCESS_SUBTENANT, payload: sitemapData });
    } catch (e: any) {
        yield put({ type: SITE_MAP.FAIL_SUBTENANT, error: e });
    }
}

export default function* watchSitemapSaga() {
    yield takeEvery(SITE_MAP.LOAD, getSitemapData);
    yield takeEvery(SITE_MAP.LOAD_SUBTENANT, getSubTenantSitemapData);

}