import { SEARCH_PLACE } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    searchData: {}
}

const searchPlaceReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SEARCH_PLACE.LOAD:
            return {
                ...state,
                loading: true
            };
        case SEARCH_PLACE.SUCCESS:
            return {
                ...state,
                loading: false,
                searchData: action.payload,
                error: ''
            };
        case SEARCH_PLACE.FAIL:
            return {
                ...state,
                loading: false,
                searchData: {},
                error: action.error
            }
        default:
            return state;
    }
}

export default searchPlaceReducer;
