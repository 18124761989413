import { call, put, takeEvery } from 'redux-saga/effects';

import { getAllPlaceApi, getSearchPlaceApi } from '../../Api';
import { SEARCH_PLACE } from '../actionType';

export function* getSearchPlaceData(action: any): any {
  try {
    const searchPlaceData: any = yield call(() =>
      getSearchPlaceApi(action.payload),
    );
    yield put({ type: SEARCH_PLACE.SUCCESS, payload: searchPlaceData });
  } catch (e: any) {
    yield put({ type: SEARCH_PLACE.FAIL, error: e });
  }
}

export function* getAllPlaceData(): any {
  try {
    const searchPlaceData: any = yield call(() => getAllPlaceApi());
    yield put({ type: SEARCH_PLACE.SUCCESS, payload: searchPlaceData });
  } catch (e: any) {
    yield put({ type: SEARCH_PLACE.FAIL, error: e });
  }
}

export default function* watchSearchPlaceSaga() {
  yield takeEvery(SEARCH_PLACE.LOAD, getSearchPlaceData);
  yield takeEvery(SEARCH_PLACE.LOAD_ALL, getAllPlaceData);
}
