import { call, put, takeEvery } from 'redux-saga/effects';

import { getPromotionDataApi, getSubTenantPromotionDataApi } from '../../Api';
import { PROMOTION_DATA } from '../actionType';

export function* getPromotionData(action: any): any {
  try {
    const promotionData: any = yield call(() =>
      getPromotionDataApi(action.payload),
    );
    yield put({ type: PROMOTION_DATA.SUCCESS, payload: promotionData });
  } catch (e: any) {
    yield put({ type: PROMOTION_DATA.FAIL, error: e });
  }
}

export function* getSubTenantPromotionData(action: any): any {
  try {
    const promotionData: any = yield call(() =>
      getSubTenantPromotionDataApi(action.payload),
    );
    yield put({ type: PROMOTION_DATA.SUCCESS_SUBTENANT, payload: promotionData });
  } catch (e: any) {
    yield put({ type: PROMOTION_DATA.FAIL_SUBTENANT, error: e });
  }
}

export default function* watchPromotionData() {
  yield takeEvery(PROMOTION_DATA.LOAD, getPromotionData);
  yield takeEvery(PROMOTION_DATA.LOAD_SUBTENANT, getSubTenantPromotionData);

}
