import { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import { CONSTS } from '../../Common/CONSTS';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back_arrow.svg';
import { SitemapRow } from '../../types';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerLogo: {
      maxWidth: 124,
      paddingTop: 16,
      paddingBottom: 20,
      maxHeight: 72.5,

      [theme.breakpoints.up('sm')]: {
        maxWidth: 200,
        maxHeight: 95,
      },
    },

    backArrow: {
      position: 'absolute',
      top: 20,
      left: 20,
      cursor: 'pointer',
    },

    categoryLandingHeaderText: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19px',
    },
  }),
);

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const tenantData = useSelector((state: RootStateOrAny) => state.tenantData);
  const profileData = useSelector(
    (state: RootStateOrAny) => state.tenantProfileData,
  );

  const { subTenantData } = useSelector(
    (state: RootStateOrAny) => state.subTenantData,
  );

  const sitemapData = useSelector((state: RootStateOrAny) => state.sitemapData);
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sitemapRows = sitemapData?.sitemapData?.rows;
  const sitemapRowsSubTenant = sitemapData?.sitemapDataSubTenant?.rows;

  const logoUrl =
    tenantData.tenantData.settings?.logos[0]?.downloadUrl ||
    subTenantData.settings?.logos[0]?.downloadUrl;

  const textNumber = profileData?.tenantProfileData?.rows[0]?.textNumber;
  const smsLink = 'sms:' + textNumber;
  const tenantName = tenantData.tenantData?.name;

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const renderMain = () => {
    const paths = window.location.pathname.split('/').filter((_) => !!_);
    const pathLength = paths.length;

    return pathLength > 2 && paths[1] !== 'event' ? (
      <motion.div
        variants={CONSTS.TRANSITIONS.VARIANTS.DEFAULT_PAGE_VARIANTS}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <BackArrowIcon
          className={classes.backArrow}
          onClick={() => navigate(-1)}
        />
        <Typography
          fontWeight="h1"
          className={classes.categoryLandingHeaderText}
        >
          {
            paths[2].toUpperCase()
            // .split('-')
            // .join(' ')
          }
        </Typography>
      </motion.div>
    ) : (
      <motion.div
        variants={CONSTS.TRANSITIONS.VARIANTS.DEFAULT_PAGE_VARIANTS}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {pathLength > 0 && (
          <BackArrowIcon
            className={classes.backArrow}
            onClick={() => navigate(-1)}
          />
        )}

        <NavLink to="/" style={{ padding: 0 }}>
          <img src={logoUrl} alt={tenantName} className={classes.headerLogo} />
        </NavLink>
      </motion.div>
    );
  };

  const renderHamburgerButton = () => {
    return (
      <div
        className={clsx('hamburgerBtn', {
          active: isMenuOpen,
        })}
        onClick={toggleMenu}
      >
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
      </div>
    );
  };

  const renderMenuItems = () => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return (
      <div className={clsx('menu-items', { active: isMenuOpen })}>
        <NavLink
          to="/"
          end
          className={({ isActive }) => clsx('menu-item', { active: isActive })}
          onClick={toggleMenu}
        >
          Home
        </NavLink>
        <NavLink
          to="/resort-map"
          className={({ isActive }) => clsx('menu-item', { active: isActive })}
          onClick={toggleMenu}
        >
          Map
        </NavLink>

        {sitemapRowsSubTenant?.map(({ name, sitemap }: SitemapRow) => (
          <NavLink
            key={name}
            to={`/site-map/${sitemap[0]?.category?.slug}?isSubTenant=true`}
            className={({ isActive }) =>
              clsx('menu-item', { active: isActive })
            }
            onClick={toggleMenu}
          >
            {name}
          </NavLink>
        ))}

        {sitemapRows?.map(({ name, sitemap }: SitemapRow) => (
          <NavLink
            key={name}
            to={`/site-map/${sitemap[0]?.category?.slug}`}
            className={({ isActive }) =>
              clsx('menu-item', { active: isActive })
            }
            onClick={toggleMenu}
          >
            {name}
          </NavLink>
        ))}

        <a href={smsLink} className="menu-item" onClick={toggleMenu}>
          Text us
        </a>
      </div>
    );
  };

  return (
    <>
      <div id="appHeader">
        {renderMain()}
        {renderHamburgerButton()}
      </div>

      {renderMenuItems()}
    </>
  );
};

export default Header;
