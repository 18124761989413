import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { memo, useRef } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import TinySlider from 'tiny-slider-react';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right_arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left_arrow.svg';
import { CONSTS } from '../../Common/CONSTS';

import clsx from 'clsx';
import SimpleImage from '../../Component/SimpleImage';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // misc
    indicator: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-100%)',
      cursor: 'pointer',
      boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.15)',
      backdropFilter: 'blur(2px)',
      borderRadius: 0,
      zIndex: CONSTS.Z_INDEX.LEVEL_1.FIRST,

      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },

    font: {
      fontFamily: CONSTS.FONTS.SECONDARY,
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontSize: 16,
    },
    // component
    mainContainer: {
      position: 'relative',
      marginBottom: 10,

      '& .tns-outer': {
        position: 'relative',

        '& > button': {
          display: 'none',
        },

        '& .tns-nav': {
          width: '100%',
          textAlign: 'center',

          '& button': {
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            backgroundColor: '#fff',
            border: `1px solid ${theme.palette.primary.main}`,
            margin: '0 5px',
            padding: 0,
            transition: 'all 0.3s ease-in-out',
            cursor: 'pointer',

            '&.tns-nav-active': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },

    rightIndicator: {
      right: 2,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    },

    leftIndicator: {
      left: 2,
    },

    promotionSkeleton: {
      width: '100%',
      paddingBottom: '100%',
    },

    promotionText: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      color: 'white',
      padding: '0 20px',
      paddingBottom: 24,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    },

    promotionTitle: {
      textTransform: 'uppercase',
      fontWeight: 500,

      [theme.breakpoints.up('sm')]: {
        fontSize: 'min(3vw, 24px)',
        lineHeight: 'min(3vw, 24px)',
      },
    },

    promotionSubtitle: {
      maxWidth: 500,

      [theme.breakpoints.up('sm')]: {
        marginTop: 8,
        fontSize: 'min(3vw, 22px)',
        lineHeight: 'min(3vw, 22px)',
      },
    },

    promotionCta: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',

      [theme.breakpoints.up('sm')]: {
        marginTop: 5,
        fontSize: 'min(3vw, 18px)',
      },
    },
  }),
);
const Promotions = memo(() => {
  const classes = useStyles();
  const promotionRef: any = useRef();
  const { promotionData, promotionDataSubTenant } = useSelector(
    (state: RootStateOrAny) => state.promotionData,
  );

  const promotions = [
    ...(promotionDataSubTenant?.rows ?? []),
    ...(promotionData?.rows ?? []),
  ];

  const goToDir = (dir: string) => {
    if (dir === 'left') {
      promotionRef.current.slider.goTo('prev');
    } else {
      promotionRef.current.slider.goTo('next');
    }
  };

  const ContainerWithIndicators = ({ children }: any) => (
    <div className="slider-container">
      {promotions.length > 1 ? (
        <>
          <IconButton
            className={clsx(classes.indicator, classes.leftIndicator)}
            onClick={() => goToDir('left')}
          >
            <LeftArrowIcon />
          </IconButton>
          {children}
          <IconButton
            className={clsx(classes.indicator, classes.rightIndicator)}
            onClick={() => goToDir('right')}
          >
            <RightArrowIcon />
          </IconButton>
        </>
      ) : (
        children
      )}
    </div>
  );

  const handlePromotionClick = (item) => {
    if (item?.attachment[0] && item?.attachment[0]?.downloadUrl) {
      const newWindow = window.open(item.attachment[0].downloadUrl, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    } else if (item?.redirectURL) {
      const newWindow = window.open(item.redirectURL, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    }
  };

  return (
    promotions.length > 0 && (
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.5, ease: 'easeInOut' },
        }}
        className={classes.mainContainer}
      >
        <ContainerWithIndicators>
          <TinySlider
            ref={promotionRef}
            settings={CONSTS.SLIDER_SETTINGS.PROMOTION}
          >
            {promotions?.map((promotionItem: any, index: number) => (
              <Card sx={{ maxWidth: '100%' }} key={index}>
                <CardActionArea
                  onClick={() => handlePromotionClick(promotionItem)}
                >
                  <SimpleImage
                    loading="eager"
                    src={promotionItem?.coverImage[0]?.downloadUrl}
                    alt="promotion"
                    width="100%"
                    // forceLoad={true}
                    skeletonProps={{
                      className: classes.promotionSkeleton,
                    }}
                  />
                  {promotionItem.teaser ? (
                    <Box className={classes.promotionText}>
                      <Typography
                        component="p"
                        className={clsx(classes.font, classes.promotionTitle)}
                      >
                        {promotionItem?.title}
                      </Typography>
                      <Typography
                        component="p"
                        className={clsx(classes.font, classes.promotionSubtitle)}
                      >
                        {promotionItem?.teaser}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={clsx(classes.font, classes.promotionCta)}
                      >
                        <u>Click here</u>
                      </Typography>
                    </Box>
                  ) : null}
                </CardActionArea>
              </Card>
            ))}
          </TinySlider>
        </ContainerWithIndicators>
      </motion.div>
    )
  );
});

export default Promotions;