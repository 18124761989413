import { call, put, takeEvery } from 'redux-saga/effects';

import { getSubTenantByUrl, getTenantDataApi } from '../../Api';
import { setLocalStorageItem } from '../../Common/utils';
import { tenantUtils } from '../../Modules/Tenants/tenantSubdomain';
import {
  ONLINE_ORDER_DATA,
  PROMOTION_DATA,
  SITE_MAP,
  TENANT_DATA,
  TENANT_PROFILE_DATA,
  EVENT_DATA,
  SEARCH_PLACE,
} from '../actionType';

export function* getTenantData(action: any): any {
  try {
    const tenantData: any = yield call(() => getTenantDataApi(action.payload));
    yield put({ type: TENANT_DATA.SUCCESS, payload: tenantData });
    if (tenantData?.id) {
      setLocalStorageItem('tenantId', {
        id: tenantData?.id,
        name: tenantData?.name || 'Resort',
      });

      // if (tenantUtils.isSubTenantPresent) {
      //   const subTenantData: any = yield call(() =>
      //     getSubTenantByUrl(tenantUtils.getSubDomains().subTenant),
      //   );

      //   if (subTenantData?.id) {
      //     setLocalStorageItem('subTenant', {
      //       id: subTenantData?.id,
      //       name: subTenantData?.name,
      //     });
      //   }
      // }
      yield put({ type: TENANT_PROFILE_DATA.LOAD, payload: tenantData.id });
      yield put({ type: ONLINE_ORDER_DATA.LOAD, payload: tenantData.id });
      yield put({ type: PROMOTION_DATA.LOAD, payload: { tenantId: tenantData.id } });
      yield put({ type: EVENT_DATA.LOAD, payload: { tenantId: tenantData.id } });
      yield put({ type: SITE_MAP.LOAD, payload: { tenantId: tenantData.id } });
      //added to preload all geolocations for wayfinding
      yield put({ type: SEARCH_PLACE.LOAD, payload: { tenantId: tenantData.id } });
    }
  } catch (e: any) {
    yield put({ type: TENANT_DATA.FAIL, error: e });
  }
}

export default function* watchTenantData() {
  yield takeEvery(TENANT_DATA.LOAD, getTenantData);
}
