import { getOnlineOrderService } from "../Service";

export const getOnlineOrderDataApi = async (tenantId: string) => {
    try {
        const response = await getOnlineOrderService(tenantId);
        const data = response.data;
        return data
    } catch (error) {
        throw Error
    }
}