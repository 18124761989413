import axios from 'axios';

export const getPromotionService = async (action) => {
  const tenantId = action.tenantId;

  return await axios.get(`tenant/${tenantId}/promotion/guest`);
};

export const getSubTenantPromotionService = async (action) => {
  const tenantId = action.tenantId;
  const subId = action.subTenantId;
  return await axios.get(`/tenant/${tenantId}/promotion/guest`, {
    params: {
      filter: { subTenantId: subId },
    },
  });
};
