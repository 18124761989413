import { combineReducers } from 'redux';
import menuDataReducer from './menuDataReducer';
import promotionReducer from './promotionReducer';
import onlineOrderReducer from './onlineOrderReducer';
import searchPlaceReducer from './searchPlaceReducer';
import sitemapBySlugReducers from './sitemapBySlugReducers';
import sitemapReducer from './sitemapReducer';
import tenantReducer from './tenantReducer';
import tenantProfileReducer from './tenantProfileReducer';
import eventDataReducer from './eventReducer';
import subTenantReducer from './subTenantReducer';

const rootReducer = combineReducers({
  tenantData: tenantReducer,
  tenantProfileData: tenantProfileReducer,
  promotionData: promotionReducer,
  onlineOrderData: onlineOrderReducer,
  sitemapData: sitemapReducer,
  sitemapBySlugData: sitemapBySlugReducers,
  searchPlaceData: searchPlaceReducer,
  menuData: menuDataReducer,
  eventData: eventDataReducer,
  subTenantData:subTenantReducer
});

export default rootReducer;
