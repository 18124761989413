import { TENANT_DATA } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    success: '',
    tenantData: {}
}

const tenantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TENANT_DATA.LOAD:
            return {
                ...state,
                loading: true,
                success: '',
            };
        case TENANT_DATA.SUCCESS:
            return {
                ...state,
                loading: false,
                tenantData: action.payload,
                error: '',
                success: true,
            };
        case TENANT_DATA.FAIL:
            return {
                ...state,
                loading: false,
                tenantData: {},
                error: action.error,
                success: false,
            }
        default:
            return state;
    }
}

export default tenantReducer;
