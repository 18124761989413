import { getEventService, getSubTenantEventService } from '../Service/eventService';

export const getEventApi = async (action) => {
  try {
    const response = await getEventService(action);
    const data = await response.data;
    return data;
  } catch (Error) {
    throw Error;
  }
};


export const getSubTenantEventApi = async (action) => {
  try {
    const response = await getSubTenantEventService(action);
    const data = await response.data;
    return data;
  } catch (Error) {
    throw Error;
  }
};
