export const CONSTS = {
  TRACKING_ID: 'UA-11000532-3',

  FONTS: {
    PRIMARY: 'Roboto',
    SECONDARY: 'Inter',
  },

  MAPPING: {
    DAY_TO_NUMBER: {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    },
  },

  SLIDER_SETTINGS: {
    HOME_CATEGORY: {
      lazyload: true,
      nav: false,
      controls: false,
      items: 1.8,
      loop: false,
      swipeAngle: false,
      speed: 400,
      edgePadding: 0,
      mouseDrag: true,
    },

    CATEGORY_LANDING: {
      lazyload: true,
      nav: true,
      mouseDrag: true,
      dots: true,
      controls: false,
      navPosition: 'bottom',
      speed: 1000,
      gutter: 1,
      touch: true,
      swipeAngle: 180,
      items: 1,
      autoplay: true,
      autoplayTimeout: 2500,
      rewind: true,
    },

    PROMOTION: {
      lazyload: true,
      items: 1,
      swipeAngle: false,
      mouseDrag: true,
      loop: false,
      rewind: true,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      speed: 2500,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      gutter: 10,
    },
  },

  TRANSITIONS: {
    TEST: {
      duration: 0.6,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
    VARIANTS: {
      DEFAULT_PAGE_VARIANTS: {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            ease: 'easeIn',
            duration: 0.5,
          },
        },
        exit: {
          opacity: 0,
          transition: { ease: 'easeInOut' },
        },
      },
    },
  },

  Z_INDEX: {
    LEVEL_1: {
      FIRST: 1,
      LAST: 99,
    },
    LEVEL_2: {
      FIRST: 100,
      SECOND: 101,
      LAST: 9999,
    },
    LEVEL_3: {
      FIRST: 10000,
    },
  },

  FORBIDDEN_SUBDOMAINS: ['www', 'map'],
};
