import { MENU_DATA } from '../actionType/menuType';

const initialState = {
  loading: false,
  error: '',
  menuData: {},
};

const menuDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_DATA.LOAD:
      return {
        ...state,
        loading: true,
      };
    case MENU_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        menuData: action.payload,
        error: '',
      };
    case MENU_DATA.FAIL:
      return {
        ...state,
        loading: false,
        menuData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default menuDataReducer;
