import { getTenantProfileService } from "../Service";

export const getTenantProfileDataApi = async (tenantId: string) => {
    try {
        const response = await getTenantProfileService(tenantId);
        const data = response.data;
        return data
    } catch (error) {
        throw Error
    }
}