import { createStyles, makeStyles } from '@mui/styles';
import { RootStateOrAny, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    footer: {
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0px auto',
      height: 75,
      width: '100%',
      maxWidth: 768,
      minWidth: 320,
      WebkitBackdropFilter: 'blur(10px)',
      backgroundColor: theme.palette.primary.main,
      zIndex: 10001,
    },
    tab: {
      width: '25%',
      textAlign: 'center',
      color: '#fff',
      cursor: 'pointer',
    },
    link: {
      color: 'white',
      textDecorationLine: 'none',
    },
    img: {
      width: '30px',
      margin: '0px auto 5px',
      WebkitFilter: 'brightness(0) invert(1)',
      filter: 'brightness(0) invert(1)',
    },
    p: {
      fontSize: '12px',
    },
  }),
);

const footerLinks = [
  {
    name: 'Home',
    path: '/',
    icon: '/assets/image/home-icon.png',
  },
  {
    name: 'Text Us',
    icon: '/assets/image/text-icon.png',
    isSms: true,
  },
  {
    name: 'Calendar',
    path: '/calendar',
    icon: '/assets/image/calender-icon.png',
  },
  {
    name: 'Resort Map',
    path: '/resort-map',
    icon: '/assets/image/map-icon.png',
  },
];

const Footer = () => {
  const classes = useStyles();
  const profileData = useSelector(
    (state: RootStateOrAny) => state.tenantProfileData,
  );

  const textNumber = profileData?.tenantProfileData?.rows[0]?.textNumber;
  const smsLink = 'sms:' + textNumber;

  return (
    <div className={classes.footer}>
      {footerLinks.map((link, index) => (
        <div className={classes.tab} key={index}>
          {link.isSms ? (
            <a className={classes.link} href={smsLink}>
              <img className={classes.img} src={link.icon} alt={link.name} />
              <p className={classes.p}>{link.name}</p>
            </a>
          ) : (
            <NavLink className={classes.link} to={link.path}>
              <img className={classes.img} src={link.icon} alt={link.name} />
              <p className={classes.p}>{link.name}</p>
            </NavLink>
          )}
        </div>
      ))}
    </div>
  );
};

export default Footer;
