import { SUBTENANT_DATA } from "../actionType";

const initialState = {
    loading: false,
    error: '',
    subTenantData: {}
}

const subTenantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SUBTENANT_DATA.LOAD:
            return {
                ...state,
                loading: true
            };
        case SUBTENANT_DATA.SUCCESS:
            return {
                ...state,
                loading: false,
                subTenantData: action.payload,
                error: ''
            };
        case SUBTENANT_DATA.FAIL:
            return {
                ...state,
                loading: false,
                subTenantData: {},
                error: action.error
            }
        default:
            return state;
    }
}

export default subTenantReducer;
