import { toast } from 'react-toastify';

export const createAlert = ({ type, message }, config = {}) =>
  toast[type](message, { ...config });

export const getTitleFromPath = (path: string) => {
  const title = path
    .split('/')
    .filter((item) => item)
    .pop();

  // replace - with space & capitalize first letter
  return title
    ?.replace(/-/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
    );
};

export const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const setLocalStorageItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const debounce = (func: any, wait: number, immediate?: boolean) => {
  let timeout: any;
  return function executedFunction(...args: any) {
    const later = () => {
      timeout = null;
      if (!immediate) func(...args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func(...args);
  };
};

// by default, it will give Sunday of Current Week
export const getSundayOfWeek = (date = new Date()) => {
  const diff = date.getDate() - date.getDay();
  return new Date(date.setDate(diff));
};
