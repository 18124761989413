import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'mi-map-googlemaps': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'mi-map-mapbox': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'mi-search': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'mi-list': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
function Map() {
  const tenantData = useSelector((state: RootStateOrAny) => state.tenantData);

  const [miApiKey, setMiApiKey] = useState('');
  const [gmApiKey, setGmApiKey] = useState('');
  const [center, setCenter]: any = useState([]);

  useEffect(() => {
    if (tenantData.loading === false) {
      setGmApiKey(tenantData?.tenantData?.settings?.gmApiKey);
      setMiApiKey(tenantData?.tenantData?.settings?.miApiKey);
    }
  }, [tenantData.loading, tenantData?.tenantData?.settings]);

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          floor: 0,
        });
      });
    }
  }, []);

  useEffect(() => {
    const miMapElement = document.querySelector('mi-map-googlemaps');
    const miSearchElement = document.querySelector('mi-search');
    const miListElement = document.querySelector('mi-list');
    miSearchElement.setAttribute('mapsindoors', 'true');
    miSearchElement.setAttribute('zoom', '19');
    // show map
    miMapElement.addEventListener('mapsIndoorsReady', () => {
      miMapElement.getMapInstance().then((mapInstance) => {
        mapInstance.setCenter(center);
      });
      miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
        mapsIndoorsInstance.addListener('click', (location) => {
          setRoute(center, location);
        });
      });
    });
    // search
    miSearchElement.addEventListener('results', (event: any) => {
      miListElement.innerHTML = null;
      event.detail.forEach((location) => {
        const miListItemElement = document.createElement(
          'mi-list-item-location',
        );
        miListItemElement.location = location;
        miListElement.appendChild(miListItemElement);
      });
      miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
        mapsIndoorsInstance.filter(
          event.detail.map((location) => location.id),
          false,
        );
      });
    });
    miListElement.addEventListener('locationClicked', (location: any) => {
      setRoute(center, location.detail);
      miListElement.innerHTML = null;
      miSearchElement.value = location.detail
        ? location.detail.properties.name
        : (miListElement.innerHTML = null);
    });

    function getAnchorCoordinates(location) {
      return location.geometry.type.toLowerCase() === 'point'
        ? {
            lat: location.geometry.coordinates[1],
            lng: location.geometry.coordinates[0],
          }
        : {
            lat: location.properties.anchor.coordinates[1],
            lng: location.properties.anchor.coordinates[0],
          };
    }

    function setRoute(center, destination) {
      const destCoordinates = getAnchorCoordinates(destination);
      debugger;
      const routeParams = {
        origin: center,
        destination: {
          lat: destCoordinates.lat,
          lng: destCoordinates.lng,
          floor: destination.properties.floor,
        },
        travelMode: 'WALKING',
      };
      miMapElement.getMapInstance().then((mapInstance) => {
        mapInstance.setCenter(routeParams.destination);
      });
      miMapElement
        .getDirectionsServiceInstance()
        .then((directionsServiceInstance) =>
          directionsServiceInstance
            .getRoute(routeParams)
            .then((directionsResult) => {
              miMapElement
                .getDirectionsRendererInstance()
                .then((directionsRendererInstance) =>
                  directionsRendererInstance.setRoute(directionsResult),
                );
            }),
        );
    }
  });

  return (
    <div className="map">
      <mi-search
        style={{ padding: '5px 0px' }}
        // placeholder="Search"
      ></mi-search>
      <mi-list
        style={{ width: '730px' }}
        scroll-buttons-enabled="false"
      ></mi-list>
      <mi-map-googlemaps
        style={{
          // width: '730px',
          height: '845px',
        }}
        mi-api-key={miApiKey ? miApiKey : '1d402eb52f814a319e194c8a'}
        gm-api-key={
          gmApiKey ? gmApiKey : 'AIzaSyAyWPZVAo4AF_UDSlvMmQyN3nFCKCKuNNA'
        }
        floor-selector-control-position="LEFT_CENTER"
      ></mi-map-googlemaps>
    </div>
  );
}

export default Map;
